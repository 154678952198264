
import { useContext, useEffect, useRef, useState } from "react";
import { ApiContext } from "../ApiContext.jsx";
import  "../../style-sheets/Login.css";
// import { useDispatch,useSelector } from "react-redux";
import bcrypt from 'bcryptjs';
import { Alert } from "../alert_components/Alert/Alert.js";
import { NavLink,useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userDataAction } from "../../redux/actions/userDataAction.js";
import { EmailSvg } from "../svg_components/EmailSvg.jsx";
import { handleEmailRequired, handleNotBlank } from "../../utils/utils.js";
import { PasswordSvg } from "../svg_components/PasswordSvg.jsx";

// import { jwt } from 'jwt-decode';
// import { Cookies } from 'universal-cookie';

export const PasswordSend = () => {
    let emailInput = useRef(null);
    let firstInputRef = useRef(null);
    

    const navigate = useNavigate();
    var inputs = [];
    let verifyCodeInput = useRef(undefined);

    const { BASE_URL,PORT } = useContext(ApiContext);
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const dispatch = useDispatch();
    const [steps,setSteps] = useState(0); 
    
    const [codeNumbersFormData,setCodeNumbersFormData] = useState({
        "first-number-input" : "",
        "second-number-input" : "",
        "third-number-input" : "",
        "fourth-number-input" : "",
    });
    
    const passwordInput = useRef(null);
    const passwordRepeatInput = useRef(null);

    const[dataRecovery,setDataRecovery] = useState({
        email: "",
        password: "",
        repeat_password: "",
        code: ""
    });

    const [error,setError] = useState({
        errorAuth:false,
        errorServer:false
    })
    
    const [noMatchPassword,setNoMatchPassword] = useState(false);

    const handlerInputs = (e) =>{
        const {name , value} = e.target;
        dataRecovery[name] = value;
    }

    const handleMatchPassword = (e) => {
        dataRecovery[e.target.name] = e.target.value;
    
        if (dataRecovery.password != dataRecovery.repeat_password){
            setNoMatchPassword(true);
            return;
        }
        setNoMatchPassword(false);
    }

    const handleKeyUp = (e) => {
        inputs = document.querySelectorAll(".input-field input");

        let input = e.target;
        let index1 = input.getAttribute("index");

        // Gets the next sibling element of the current input element and stores it in the nextInput variable
        // and the previous sibling element of the current input element and stores it in the prevInput variable
        const currentInput = input,
        nextInput = input.nextElementSibling,
        prevInput = input.previousElementSibling;
  
        // if the value has more than one character then clear it
        if (currentInput.value.length > 1) {
            currentInput.value = "";
            return;
        }
        // if the next input is disabled and the current value is not empty
        //  enable the next input and focus on it
        if (nextInput && nextInput.hasAttribute("disabled") && currentInput.value !== "") {
            nextInput.removeAttribute("disabled");
            nextInput.focus();
        }
      
        // if the backspace key is pressed
        if (e.key === "Backspace") {
            // iterate over all inputs again
            inputs.forEach((input, index2) => {
                // if the index1 of the current input is less than or equal to the index2 of the input in the outer loop
                // and the previous element exists, set the disabled attribute on the input and focus on the previous element
                if (index1 <= index2 && prevInput) {
                input.setAttribute("disabled", true);
                input.value = "";
                prevInput.focus();
                }
        });
        }
        //if the fourth input( which index number is 3) is not empty and has not disable attribute then
        //add active class if not then remove the active class.
        if (!inputs[3].disabled && inputs[3].value !== "") {
            verifyCodeInput.current.classList.remove("verify-code-ban");
        return;
        }
        verifyCodeInput.current.classList.add("verify-code-ban");
    };

    const handleInputsCodeNumberChange = (e) => {
        setCodeNumbersFormData((prevData) => ({...prevData,[e.target.name] : e.target.value}));
    }
  
    const handleSteps = () => {
        switch(steps){
            case 0:
                return <>
                        <div className="ContInpPlaceholder ContInpPlaceholderLog ContInpPlaceholderUser">
                            <input autoComplete='off' id="email" className="no-padding inputLogin" onInput={(e) => handleEmailRequired(e.target)} onChange={handlerInputs} name="email" required ></input>
                            <label className="placeholder" ref={emailInput} htmlFor="email">Email</label>
                            { <EmailSvg/> } 
                        </div>

                        <button className="buttonLogin" type="reset" onClick={sendRecoveryEmail}>Send Email</button>
                      </>
            case 1:
                return <>
                        <div className="input-field">
                            <input onChange={handleInputsCodeNumberChange} name="first-number-input" ref={firstInputRef} onKeyUp={handleKeyUp} index={1} type="number" />
                            <input onChange={handleInputsCodeNumberChange} name="second-number-input" onKeyUp={handleKeyUp} index={2} type="number" disabled />
                            <input onChange={handleInputsCodeNumberChange} name="third-number-input" onKeyUp={handleKeyUp} index={3} type="number" disabled />
                            <input onChange={handleInputsCodeNumberChange} name="fourth-number-input" onKeyUp={handleKeyUp} index={4} type="number" disabled />
                        </div>

                        <button className="buttonLogin verify-code-ban" ref={verifyCodeInput} type="reset" onClick={handleVerifyCode}>Verify code</button>
                    </>
            case 2:
                return <>
                        <div className={`ContInpPlaceholder ContInpPlaceholderLog ${noMatchPassword ? "error" : ""}`}>
                            <input autoComplete='off' ref={passwordInput} id="password" className='no-padding inputLogin'  onInput={handleMatchPassword} onChange={handlerInputs} name="password" type="password" required></input>
                            <label className="placeholder" htmlFor="password">New Password</label>
                            { <PasswordSvg/> }
                        </div>
                        <div className={`ContInpPlaceholder ContInpPlaceholderLog ${noMatchPassword ? "error" : ""}`}>
                            <input autoComplete='off' ref={passwordRepeatInput} id="repeat_password" className='no-padding inputLogin'  onInput={handleMatchPassword} onChange={handlerInputs} name="repeat_password" type="password" required></input>
                            <label className="placeholder" htmlFor="repeat_password">Repeat new Password</label>
                            { <PasswordSvg/> }
                        </div>

                        <button className="buttonLogin" type="reset" onClick={sendPasswordChanged}>Save Password</button>
                    </>
        }
    }

    const errorMsg = () => {
        if (error.errorAuth){
            Alert.fire({
                "text":"The email does't exists",
                "type":"error"
            })
        }else if(error.errorServer){
            Alert.fire({
                "text":"Server error, try again",
                "type":"error"
            })
        }
    }

    const handleVerifyCode = async (e) => {
        if(verifyCodeInput.current.classList.contains("verify-code-ban")) return;

        let userCode = codeNumbersFormData['first-number-input'] + codeNumbersFormData['second-number-input'] + codeNumbersFormData['third-number-input'] + codeNumbersFormData['fourth-number-input'];

        if(userCode == dataRecovery.code){
            setSteps(2);
        }else{
            Alert.fire({
                "text":"The code doesn't match",
                "type":"error"
            })
        }
    }
    const sendPasswordChanged = async (e) => {
        let jsonData = {};

        if(dataRecovery.password == dataRecovery.repeat_password && dataRecovery.password != ""){
            let body = {
                password:bcrypt.hashSync(dataRecovery.password, 10),
                email:dataRecovery.email
            }

            dataRecovery.password = "";
            dataRecovery.repeat_password = "";
            
            let response = await fetch(`${BASE_URL}${PORT}/login/password`,{
                method:"POST",
                headers:{ "Content-Type": "application/json"},
                body:JSON.stringify(body)
            });

            if(response.status == 200){
                jsonData = await response.json();
            }

            if(jsonData.code == 200){
                Alert.fire({
                    "text":"Password successfully updated",
                    "type":"success"
                })
                navigate("/login",{replace : true});

            }else{
                Alert.fire({
                    "text":"Something went wrong, try again later",
                    "type":"error"
                })
            }

        }else{
            Alert.fire({
                "text":"The passwords doesn't match",
                "type":"error"
            })
        }
    }
    

    async function sendRecoveryEmail(e){
        e.preventDefault();
        let jsonData = {};

        if(dataRecovery.email == ""){
            emailInput.current.parentNode.classList.add("error");
            return;
        }else{
            emailInput.current.parentNode.classList.remove("error");
        }

        let body = {
            email:dataRecovery.email.trim(),
        };

        let response = await fetch(`${BASE_URL}${PORT}/login/email`,{
            method:"POST",
            headers:{ "Content-Type": "application/json"},
            body:JSON.stringify(body)
        });

        if(response.status == 200)
            jsonData = await response.json();
        
        if(response.status == 200 && jsonData.code == 200){
            Alert.fire({
                "text":"Email sended successfully",
                "type":"success"
            });
            setDataRecovery(prevData => ({...prevData,"code":jsonData?.result?.code}));

            setSteps(1);
        }
        if(jsonData.code == 404){
            Alert.fire({
                "text":jsonData.message,
                "type":"warning"
            });
        }
        if(response.status != 200){
            Alert.fire({
                "text":"Something was wrong, try again later",
                "type":"error"
            });

        }
    }

    useEffect(() => {
        if(firstInputRef.current)
            firstInputRef.current.focus();
    },[steps])

    return(
        <div className="loginPaddingDiv">
            
            <div className="loginCard">
                <div className="container-login-image">
                    <img src={require("../../resources/3d-render-little-girl-with-pink-bow-her-head.jpg")} alt="logo"/>
                </div>
                    <div className="grid-blocks">
                        <div className="flex-end-container header-login">
                            <NavLink to="/sign_up">
                                Sign up
                            </NavLink>
                            <div className="container-close-login">
                            <NavLink to={ from }>
                                <div className="close-login">
                                    <div className="left-cross-part"></div>
                                    <div className="right-cross-part"></div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <form action="" onSubmit={sendRecoveryEmail} className="formLogin" autoComplete='off'>
                        <p className="sendRecoveryEmail-paragraph" >Forgot Password</p>
                        {(error.errorAuth || error.errorServer) && errorMsg()}
                        
                        { handleSteps() }
                    </form>
                </div>
            </div>
        </div>
    )
}