
import { useContext, useState } from "react";
import { ApiContext } from "../ApiContext.jsx";
import  "../../style-sheets/Login.css";
// import { useDispatch,useSelector } from "react-redux";
import { showAlert } from "../../redux/actions/customAlertAction.js";
import { UserSvg } from "../svg_components/UserSvg.jsx";
import { PasswordSvg } from "../svg_components/PasswordSvg.jsx";
import bcrypt from 'bcryptjs';
import { Alert } from "../alert_components/Alert/Alert.js";
import { NavLink,useLocation } from "react-router-dom";
import { GoogleSvg } from "../svg_components/GoogleSvg.jsx";
import { FacebookAlternativeSvg } from "../svg_components/FacebookSvgAlternative.jsx";
import { MacSvg } from "../svg_components/MacSvg.jsx";
import { useDispatch, useSelector } from "react-redux";
import { userDataAction } from "../../redux/actions/userDataAction.js";
import { EmailSvg } from "../svg_components/EmailSvg.jsx";

// import { jwt } from 'jwt-decode';
// import { Cookies } from 'universal-cookie';

export const Login = ({navigate}) => {
    const { BASE_URL,PORT } = useContext(ApiContext);
    
    // const dispatch = useDispatch();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const dispatch = useDispatch();

    
    const[userData,SetUserData] = useState({
        first_name:"",
        password:"",
        repeat_password: "",
        last_name: "",
        email: "",
        picture: "",
        google_id: -1
    });

    const [error,setError] = useState({
        errorAuth:false,
        errorServer:false
    })
    

    const handlerInputs = (e) =>{
        const {name , value} = e.target;
        userData[name] = value;
    }

    const errorMsg = () => {
        if (error.errorAuth){
            Alert.fire({
                "text":"Incorrect email or password",
                "type":"error"
            })
        }else if(error.errorServer){
            Alert.fire({
                "text":"Server error, try again",
                "type":"error"
            })
        }
    }

    

    async function login(e){
        e.preventDefault();
        try {
            // const salt = bcrypt.genSaltSync(10);    
            const hashPassword = bcrypt.hashSync(userData.password, 10);
            
            let body = {
                email:userData.email.trim(),
                password:hashPassword.trim()
            };

            let response = await fetch(`${BASE_URL}${PORT}/login/local`,{
                method:"POST",
                headers:{ "Content-Type": "application/json"},
                body:JSON.stringify(body)
            });

            let jsonData = await response.json();
            
            if(response.status == 401 && jsonData.code == 401){
                Alert.fire({
                    "text":jsonData.message,
                    "type":"error"
                });

                return;
            }
            if(jsonData == "")
                setError((prevData) => ({...prevData,errorAuth:true}));
    
            let match = bcrypt.compareSync(userData.password,jsonData['user']['password']);

            if(!match)
                setError((prevData) => ({...prevData,errorAuth:true}));
            else{
                setError((prevData) => ({...prevData,errorServer:false,errorAuth:false}));
                
                jsonData.complete = true;
                if(!storeDataAndContinue(jsonData)){
                    return;
                }
            }

        } catch (error) {
            console.log(error)
            setError((prevData) => ({...prevData,errorServer:true}));
        }
    
    }
    const fetchAuthUser = async () => {
        const response = await fetch(`${BASE_URL}${PORT}/auth/user`,{
            method: 'GET',
            credentials: 'include',
        }).catch((error) => {
            console.log("Not properly authenticated",error);
        });

        if(response){
            if(response.status == 200){
                let jsonData = await response.json();

                if(jsonData != "")
                    if(!storeDataAndContinue(jsonData)) return;
            }
        }

    }

    const storeDataAndContinue = (jsonData) => {
        let complete = jsonData.complete
        jsonData = jsonData.user;

        if(complete){
            userData.role = jsonData['role_name'];
            userData.deep_level = jsonData['role_deep_level'];
            userData.first_name = jsonData['first_name'];
            let token = jsonData['token'];
            
            document.cookie = `auth_token=${token}; max-age=3600; path=/`;
            document.cookie = `user_name=${userData.first_name[0].toUpperCase() + userData.first_name.slice(1,userData.first_name.length)}; max-age=3600; path=/`;
            document.cookie = `user_deep_level=${userData.deep_level}; max-age=3600; path=/`;
            // navigate(userData.deep_level == 0 ? "/Admin" : from,{replace : true});
            navigate(from,{replace : true});
            Alert.fire({
                "text":"All good!",
                "type":"success"
            })
            return true;
        }else{
            delete jsonData['password'];
            dispatch(userDataAction({ user: jsonData }));
            navigate("/sign_up",{replace : true});
            return false;
        }

        
    }

    const redirectToGoogleSSO = async () => {
        let timer = null; 
        const googleLoginUrl = `${BASE_URL}${PORT}/login/google`;
        const newWindow = window.open(googleLoginUrl,"_blank","width=500,height=600");
        if(newWindow) {
            timer = setInterval(() => {
                if(newWindow.closed){
                    fetchAuthUser();
                    if(timer) clearInterval(timer);
                }
            },500);
        }
    }
    return(
        <div className="loginPaddingDiv">
            
            <div className="loginCard">
                <div className="container-login-image">
                    <img src={require("../../resources/3d-render-little-girl-with-pink-bow-her-head.jpg")} alt="logo"/>
                    {/* <img src={require("../../resources/cute-young-businesswoman-working-with-laptop-video-conference-online-communication-3d-illustration-cartoon-character.jpg")} alt="logo"/> */}
                    {/* <img src={require("../../resources/test/9886986.jpg")} alt="logo"/> */}
                </div>
                    <div className="grid-blocks">
                        <div className="flex-end-container header-login">
                            <NavLink to="/sign_up">
                                Sign up
                            </NavLink>
                            <div className="container-close-login">
                            <NavLink to={ from }>
                                <div className="close-login">
                                    <div className="left-cross-part"></div>
                                    <div className="right-cross-part"></div>
                                </div>
                            </NavLink>
                        </div>
                        </div>
                    <form action="" onSubmit={login} className="formLogin" autoComplete='off'>
                        <p className="login-paragraph" >Login</p>
                        {(error.errorAuth || error.errorServer) && errorMsg()}
                        <div className="ContInpPlaceholder ContInpPlaceholderLog ContInpPlaceholderUser">
                            <input autoComplete='off' id="email" className="no-padding inputLogin" onChange={handlerInputs} name="email" required ></input>
                            <label className="placeholder" htmlFor="email">Email</label>
                            { <EmailSvg/> } 
                        </div>
                        <div className="ContInpPlaceholder ContInpPlaceholderLog ContInpPlaceholderPassword">
                            <input autoComplete='off' id="password" className="no-padding inputLogin"  onChange={handlerInputs} name="password" type="password" required></input>
                            <label className="placeholder" htmlFor="password">Password</label>
                            { <PasswordSvg/> }
                        </div>
                            <div className="flex-end-container">
                                <NavLink to="/forgot-password">
                                    Forgot password
                                </NavLink>
                            </div>
                        <button className="buttonLogin" type="reset" onClick={login}>Done</button>
                    </form>
                    <div className="flex-container flex-column continue-with-container">
                        <div className="flex-container">
                            Continue with
                        </div>
                        <div className="options-to-continue-container">
                            <div className="options-to-continue" onClick={redirectToGoogleSSO}>
                                <GoogleSvg />
                            </div>
                            <div className="options-to-continue" onClick={redirectToGoogleSSO}>
                                <MacSvg />
                            </div>
                            <div className="options-to-continue facebook-container" onClick={redirectToGoogleSSO}>
                                <FacebookAlternativeSvg />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}