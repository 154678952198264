
export const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key === name) {
            return value;
        }
    }
    return null;
}

export const handleIsValidField = (value,type,isRequired) => {
    if(isRequired == undefined)
        return true;

    else if (isRequired){
        let no_error = true;

        switch(type){
            case "email":
                no_error = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
                break
            case "number":
                no_error = /^[0-9]*$/.test(value);
                break
        }
        return value != "" && no_error;
    }
    else{
        return false;
    }
}

export const handleFormChange = (target,values,setValues) => {
    let value = "";
    let name = "";

    if(target.tagName == "INPUT" || target.tagName == "SELECT") {
        value = target.value;
        name =  target.name;
    }else{
        value = target.getAttribute("value");
        name = target.getAttribute("name");
    } 

    if(values?.[name]){
        let error = !handleIsValidField(value,values?.[name]['type'],values?.[name]['required'])
        setValues((prevState) => ({...prevState,[name] : {value:value,error:error,required:prevState[name]['required'],type:prevState[name]['type']}}));
    }else{
        setValues((prevState) => ({...prevState,[name] : {value:value,error:false}}));
    }
}


export const handleEmailRequired = (element) => {

    if(/^(?=.*\S).*$/.test(element.value.trim()) && /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(element.value.trim())){
        element.parentNode.classList.remove("error");
        return true;
    }else{
        element.parentNode.classList.add("error");
        return false
    }
}

export const handleNotBlank = (element) => {

    if(/^(?=.*\S).*$/.test(element.value.trim())){
        element.parentNode.classList.remove("error");
        return true;
    }else{
        element.parentNode.classList.add("error");
        return false
    }
}

export const mapDataAndCheckValid = (values,setValues,numOfChildren) => {
    let canSend = true;

    console.log("numOfChildren",numOfChildren)
    let data = {};
    let keysValues = Object.keys(values);
    let firstErrorElementName = "";
    let errorFields = [];

    for(let i = 0;i < keysValues.length;i++){
        let name = keysValues[i];

        //NOT REQUIRED FIELDS AND NOT INCLUDED FIELDS IN THE SETUPS LIKE THE QUILLS
        if(values[keysValues[i]]['required'] == undefined && !keysValues[i].includes("child")){
            if(values[keysValues[i]]['value'].replaceAll(/(&nbsp;|<([^>]+)>)/ig,"") == "") continue;

            setValues((prevState) => ({...prevState,[name] : {...prevState[name],["error"] : false}}));
        }else{
            console.log("inside",keysValues[i])
            if(keysValues[i].includes("child")){
                if(!data?.["children-data"]) data["children-data"] = {"child-element-count" : numOfChildren,"children-array" : {}}; 
                
                data["children-data"]["children-array"][keysValues[i]] = values[keysValues[i]]['value'];
                continue;
            }
            else if(!handleIsValidField(values[keysValues[i]]['value'],values[keysValues[i]]['type'],values[keysValues[i]]['required'])){
                if(firstErrorElementName == "")
                    firstErrorElementName = keysValues[i]
                
                errorFields.push(keysValues[i]);
                canSend = false;

                setValues((prevState) => ({...prevState,[name] : {...prevState[name],["error"] : true}})); 
            }else{
                data[keysValues[i]] = values[keysValues[i]]['value'];
                setValues((prevState) => ({...prevState,[name] : {...prevState[name],["error"] : false}})); 
            }
        }
    }

    return {"errorFields" : errorFields,"firstErrorElementName" : firstErrorElementName,"data" : data}  
}
