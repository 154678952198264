import { Routes,Route, Outlet, useLocation } from "react-router-dom";
import { Login } from './login/Login';
import { lazy,Suspense, useLayoutEffect } from "react";
import { getCookie } from "../utils/utils";
import { NavBar } from './NavBar';
import { ServicesSvg } from "./svg_components/ServicesSvg";
import { AboutSvg } from "./svg_components/AboutSvg";
import { HelpSvg } from "./svg_components/HelpSvg";
import { Footer } from "./Footer";
import { LoginSuccess } from "./login/LoginSuccess";
import { SignUp } from "./login/SignUp";
import Help from "./help/Help";
import Docs from "./Docs";
import About from "./About";
import Babysitter from "./BabySitter";
import Services from "./Services";
import Process from "./Process";
import { RequestServiceSvg } from "./svg_components/RequestServiceSvg";
import { ProcessSvg } from "./svg_components/ProcessSvg";
import { BabysitterSvg } from "./svg_components/BabysitterSvg";
import { TermsAndPrivacyPolicy } from "./TermsAndPrivacyPolicy";
import { Security } from "./Security";
import ContactCustomerForm from "./ContactCustomerForm";
import RequestMain from "./request_components/RequestMain";
import RequestANurseForm from "./request_components/RequestANurseForm";
import FAQ from "./help/tab_content/FAQ";
import HowToReachUs from "./help/tab_content/HowToReachUs";
import { PasswordSend } from "./login/PasswordSend";
import { Loading } from "./Loading";
// import { AdminPanel } from "./AdminPanel";

const AdminPanel = lazy(() => import("./AdminPanel"));
const Home = lazy(() => import("./Home"));

const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};

export const RouteHandler = ({navigate}) => {
    const authToken = getCookie('auth_token');

    const isAuth = authToken !== null;

    let admin_menu_data = [
    ]
    
    let defaultMenuData = [
    {
        'tag':'Request a Service',
        'subMenu':[
            {
                'tag' : 'Request a Babysitter',
                'to'  : '/request-babysitter',
            },
            {
                'tag' : 'Request Nurse',
                'to'  : '/request-nurse',
            },
            // {
            //     'tag' : 'Become a babysitter',
            //     'to'  : '/become-babysitter',
            // }
        ],
        'svgComponent':<RequestServiceSvg/>
    },
    {
        'tag':'Process',
        'to' : '/process',
        'svgComponent':<ProcessSvg/>
    }
    ,
    {
        'tag':'Services',
        'to' : '/services',
        'svgComponent':<ServicesSvg/>
    },
    {
        'tag':'Babysitters',
        'to' : '/babysitters',
        'svgComponent':<BabysitterSvg/>
    }
    ,
    {
        'tag':'About',
        'to' : '/about',
        'svgComponent':<AboutSvg/>
    },
    {
        'tag':'Help',
        'to' : '/help',
        'svgComponent':<HelpSvg/>
    }
]
    return(

    <>
        <Wrapper>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    {/* <Route element={<RequireAuth allowedLevels={[0,2]} />}>
                        <Route element={
                            <>
                                <NavBar navigate={navigate} data={admin_menu_data} />
                                <Outlet />
                            </>
                        }>
                            <Route exact path="/Admin" element={<AdminPanel />} />
                        </Route>
                    </Route> */}

                    {/* <Route element={<RequireAuth allowedLevels={[0,2]} />}>
                        <Route element={
                            <>
                                <NavBar navigate={navigate} data={defaultMenuData} />
                                <Outlet />
                            </>
                        }>
                        </Route>
                    </Route> */}

                    <Route element={
                        <>
                            <NavBar navigate={navigate} data={defaultMenuData} />
                            <Outlet />
                            <Footer/>
                        </>
                    }>
                        <Route exact path="/" element={<Home navigate={navigate} />} />
                        <Route exact path="/help" element={<Help />} />
                        <Route exact path="/help/how-to-reach-us" element={<HowToReachUs />} />
                        <Route exact path="/help/faq" element={<FAQ />} />
                        <Route exact path="/docs" element={<Docs />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/babysitters" element={<Babysitter />} />
                        <Route exact path="/services" element={<Services />} />
                        <Route exact path="/process" element={<Process />} />
                        <Route exact path="/terms-conditions-and-privacy" element={<TermsAndPrivacyPolicy />} />
                        <Route exact path="/security" element={<Security />} />
                        <Route exact path="/request-babysitter" element={<RequestMain />} />     
                        <Route exact path="/request-nurse" element={<RequestANurseForm />} />     
                        <Route exact path="/customer-contact-form" element={<ContactCustomerForm />} />                        
                    </Route>
                    <Route exact path="/login" element={<Login navigate={navigate} />} />
                    <Route exact path="/sign_up" element={<SignUp navigate={navigate} />} />
                    <Route exact path="/login/success" element={<LoginSuccess />} />
                    <Route exact path="/login/error" element={<LoginSuccess />} />
                    <Route exact path="/forgot-password" element={<PasswordSend />} />
                </Routes>
            </Suspense>
        </Wrapper>
        </>
);
} 
