import { useContext, useState } from "react";
import search_svg from "../../../resources/svg/search.svg";
import Question from "../../Question";
import { ApiContext } from "../../ApiContext";
import { NavLink } from "react-router-dom";
import SearchBar from "../../SearchBar";

const FAQ = () => {

    const { COMPANY_PHONE } = useContext(ApiContext);
    const [matchedQuestions,setMatchedQuestions] = useState([]);

    return(
        <div className="section-container flex-container flex-column">
            
            <SearchBar matchedQuestions={matchedQuestions} setMatchedQuestions={setMatchedQuestions}/> 

            <div className="section-content flex-container left-alignment section">
                <span className="main-title">
                    Help - FAQ
                </span>
            </div>

            {
                    matchedQuestions && matchedQuestions.length ? 
                    matchedQuestions.map((d,index) => {
                        return <Question key={index} title={d["question"]} paragraphToShow = {d["answer"]}/>
                    }) :
                    <div className="content-questions section-content">
                        <Question title="What services does Little Giggles Babysitting Company offer?" paragraphToShow = "We offer babysitting and nursing services with highly trained caregivers, including basic medical assistance and general childcare."/>
                        <Question title="How can I hire a babysitter?" paragraphToShow = "You can contact us by phone at 305-527-0300 or by email at littlegigglesbabysitter@gmail.com. You may also schedule a meeting to discuss your needs and select the right babysitter."/>
                        <Question title="What requirements do caregivers need to meet?" paragraphToShow = "All our caregivers must present valid identification, such as a passport, driver's license, and proof of residence. They undergo alcohol and drug tests and thorough background checks. All caregivers hold CPR certification, covering first aid and emergency response."/>
                        <Question title="How do you ensure the safety of the children?" paragraphToShow = "We ensure that our caregivers are fully trained and vetted. We maintain open communication with parents and provide detailed information about each caregiver."/>
                        <Question title="What if I have a problem with a babysitter?" paragraphToShow = "If you experience any issues, please contact us immediately. We are here to resolve any concerns and ensure a positive experience. You can call us at 305-527-0300 or email us at littlegigglesbabysitter@gmail.com."/>
                        <Question title="What is your cancellation policy?" paragraphToShow = "Our cancellation policy may vary depending on the type of service. Please contact us directly for specific details and any potential cancellation fees."/>
                        <Question title="How do you protect my family's privacy?" paragraphToShow = "We handle all personal information with the utmost confidentiality and security. Caregivers are also committed to respecting and protecting your family's privacy during their time in your home."/>
                        <Question title="Are there any special requirements for caregivers of children with specific needs?" paragraphToShow = "If your child has special needs, please inform us when making your request. We will do our best to assign a caregiver with the appropriate experience to meet those needs."/>
                    </div>
                }

            <div className="section-separator section-content-alt section-separator full-width"></div>

            <div className="container-contact-form">
                <div className="self-left-align flex-container section-content contact-form-main-content flex-column left-alignment">
                    <span className='contact-text full-width'>If you cannot find the answer to your questions on this page, please feel free to <NavLink to={`tel:${COMPANY_PHONE}`} className="pink-text">contact us</NavLink> via the contact form with your query.</span>
                    <NavLink to="/customer-contact-form" className="contact-form-button self-left-align rounded-pink-button standard-pink-button">
                        <div className="rounded-pink-button standard-pink-button-inner">Contact Form</div>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default FAQ;