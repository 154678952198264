import "../style-sheets/Footer.css"
import { FacebookSvg } from "./svg_components/FacebookSvg";
import { PhoneSvg } from "./svg_components/PhoneSvg";
import { WhatsappSvg } from "./svg_components/WhatsappSvg";
import { ApiContext } from "./ApiContext";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { InstagramSvg } from "./svg_components/InstagramSvg";

export const Footer = () => {
    const { COMPANY_FACEBOOK_URL,COMPANY_INSTAGRAM_URL,COMPANY_PHONE,COMPANY_EMAIL } = useContext(ApiContext);

    return(
        <footer id="principal-footer">
            <div className="grid-4-columns grid-footer">
                
            <div className="flex-container grid-group flex-column">
                    <div className="container-footer-title">
                        <label className="footer-title">Contact us</label>
                    </div>
                    <div className="footer-group">
                        <NavLink className="flex-container phone-svg-container" to={`tel:${ COMPANY_PHONE }`}>
                            <PhoneSvg/>
                            { COMPANY_PHONE }
                        </NavLink>
                        <a  className="flex-container whatsapp-svg-container" target="_blank" href={`https://wa.me/${COMPANY_PHONE}`}>
                            <WhatsappSvg/>
                            { COMPANY_PHONE }
                        </a>
                    </div> 
                </div>

                <div className="flex-container grid-group flex-column">
                    <div className="container-footer-title">
                        <label className="footer-title">Here you can find us</label>
                    </div>
                    <div className="footer-group">
                        <div className="flex-container">
                            <span>Address:</span>
                            <span>Example Address % example && example</span>
                        </div>
                        <div className="flex-container">
                            <span>Phone:</span>
                            <NavLink  to={`tel:${ COMPANY_PHONE }`}>{ COMPANY_PHONE }</NavLink>
                        </div>
                        <div className="flex-container">
                            <span>Mail:</span>
                            <NavLink  to={`mailto: ${COMPANY_EMAIL} `}>{ COMPANY_EMAIL }</NavLink>
                        </div>
                    </div> 
                </div>

                <div className="flex-container grid-group flex-column">
                    <div className="container-footer-title">
                        <label className="footer-title">Navigation</label>
                    </div>
                    <div className="footer-group">
                        <div className="flex-container">
                            <NavLink to="/request-a-babysitter">Request a Babysitter</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/request-a-nurse">Request a Nurse</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/become-a-babysitter">Become a Babysitter</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/process">Process</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/services">Services</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/babysitters">Babysitters</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/about">About</NavLink>
                        </div>
                        <div className="flex-container">
                            <NavLink to="/help">Help</NavLink>
                        </div>
                    </div> 
                </div>

                <div className="flex-container grid-group flex-column">
                    <div className="container-footer-title">
                        <label className="footer-title">Social Media</label>
                    </div>
                    <div className="footer-group">
                        <div className="flex-container extra-gap">
                            <a target="_blank" href={COMPANY_FACEBOOK_URL} className="facebook-svg-container">
                                <FacebookSvg/>
                            </a>
                            <a target="_blank" href={COMPANY_INSTAGRAM_URL} className="instagram-svg-container">
                                <InstagramSvg/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-separator"></div>
            <div className="copyright-container">
                <p>&copy; 2023 Little Giggles Babysitting Company. All Rights Reserved.</p>
            </div>
        </footer>
    );
}