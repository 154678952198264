
import { useContext, useEffect, useRef, useState } from "react";
import { ApiContext } from "../ApiContext.jsx";
import  "../../style-sheets/Login.css";
// import { useDispatch,useSelector } from "react-redux";
import { showAlert } from "../../redux/actions/customAlertAction.js";
import { UserSvg } from "../svg_components/UserSvg.jsx";
import { PasswordSvg } from "../svg_components/PasswordSvg.jsx";
import bcrypt from 'bcryptjs';
import { Alert } from "../alert_components/Alert/Alert.js";
import { NavLink, useLocation } from "react-router-dom";
import { GoogleSvg } from "../svg_components/GoogleSvg.jsx";
import { FacebookSvg } from "../svg_components/FacebookSvg.jsx";
import { FacebookAlternativeSvg } from "../svg_components/FacebookSvgAlternative.jsx";
import { MacSvg } from "../svg_components/MacSvg.jsx";
import { EmailSvg } from "../svg_components/EmailSvg.jsx";
import { useDispatch, useSelector } from "react-redux";
import { userDataAction } from "../../redux/actions/userDataAction.js";
import { handleMatchPassword, handleNotBlank } from "../../utils/utils.js";

// import { jwt } from 'jwt-decode';
// import { Cookies } from 'universal-cookie';

export const SignUp = ({navigate}) => {
    const { BASE_URL,PORT } = useContext(ApiContext);
    
    // const dispatch = useDispatch();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const dispatch = useDispatch();

    const data = useSelector(state => state?.userDataReducer?.user);
    const user = data?.user;    

    const nameInput = useRef(null);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const passwordRepeatInput = useRef(null);
    
    const[userData,setUserData] = useState({
        first_name:"",
        password:"",
        repeat_password: "",
        last_name: "",
        email: "",
        picture: "",
        google_id: -1
    });

    useEffect(() => {
        if(user){
            console.log(user)

            userData.email = user?.email ? user?.email : "";
            userData.first_name = user?.first_name ? user?.first_name : ""; 
            userData.last_name = user?.last_name ? user?.last_name : "";
            userData.google_id = user?.google_id ? user?.google_id : -1; 
            userData.email = user?.email ? user?.email : ""; 
            userData.picture = user?.picture ? user?.picture : ""; 


            nameInput.current.value = userData.first_name;
            emailInput.current.value = userData.email;
        }
    },[user])
    const [noMatchPassword,setNoMatchPassword] = useState(false);
    

    const [error,setError] = useState({
        errorAuth:false,
        errorServer:false
    })
    

    const handlerInputs = (e) =>{
        const {name , value} = e.target;
        userData[name] = value;
    }
    const errorMsg = () => {
        if (error.errorAuth){
            Alert.fire({
                "text":"Incorrect email or password",
                "type":"error"
            })
        }else if(error.errorServer){
            Alert.fire({
                "text":"Server error, try again",
                "type":"error"
            })
        }
    }


    const handleEmail = (element) => {
        if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element.value)){
            element.parentNode.classList.remove("error");
            return true;
        }else{
            element.parentNode.classList.add("error");
            return false
        }
    }
    const storeDataAndContinue = (jsonData) => {
        let complete = jsonData.complete
        jsonData = jsonData.user;
        console.log("jsonData",jsonData,complete);
        if(complete){
            userData.role = jsonData['role_name'];
            userData.deep_level = jsonData['role_deep_level'];
            userData.first_name = jsonData['first_name'];
            let token = jsonData['token'];
            
            document.cookie = `auth_token=${token}; max-age=3600; path=/`;
            document.cookie = `user_name=${userData.first_name[0].toUpperCase() + userData.first_name.slice(1,userData.first_name.length)}; max-age=3600; path=/`;
            document.cookie = `user_deep_level=${userData.deep_level}; max-age=3600; path=/`;

            navigate(from,{replace : true});
            Alert.fire({
                "text":"All good!",
                "type":"success"
            })
        }else{
            delete jsonData['password'];
            dispatch(userDataAction({ user: jsonData }));
        }
    }

    async function login(e){
        e.preventDefault();
        try {
            let canContinue = true;
                
            let first_name = nameInput.current;
            let email = emailInput.current;
            let password = passwordInput.current;
            let password_repeat = passwordRepeatInput.current;

            if(!handleEmail(email)) canContinue = false;
            if(!handleNotBlank(first_name)) canContinue = false;
            if(!handleNotBlank(password)) canContinue = false;
            if(!handleNotBlank(password_repeat)) canContinue = false;

            if(!canContinue) {
                 
                Alert.fire({
                    "text":"Check the fields!",
                    "type":"error"
                });
                
                return;
            }
            // const salt = bcrypt.genSaltSync(10);    
            const hashPassword = bcrypt.hashSync(userData.password, 10);

            let body = {
                first_name:userData.first_name,
                last_name:userData.last_name,
                password:hashPassword,
                email:userData.email,
                google_id:userData.google_id,
                email:userData.email,
                picture:userData.picture,
            };

            let response = await fetch(`${BASE_URL}${PORT}/sign_up/local`,{
                method:"POST",
                headers:{ "Content-Type": "application/json"},
                body:JSON.stringify(body)
            });

            let jsonData = await response.json();
            if(response.status == 409 && jsonData.code == 409){
                Alert.fire({
                    "text":jsonData.message,
                    "type":"warning"
                });
                return
            }

            if(jsonData == "")
                setError((prevData) => ({...prevData,errorAuth:true}));
            
            if(response.ok){
                setError((prevData) => ({...prevData,errorServer:false,errorAuth:false}));
                
                Alert.fire({
                    "text":"Account created successfully!",
                    "type":"success"
                })
                jsonData.complete = true;

                storeDataAndContinue(jsonData);

            }
            else{
                setError((prevData) => ({...prevData,errorServer:true}));
            }

        } catch (error) {
            console.log(error)
            setError((prevData) => ({...prevData,errorServer:true}));
        }
    
    }
    const fetchAuthUser = async () => {
        const response = await fetch(`${BASE_URL}${PORT}/auth/user`,{
            method: 'GET',
            credentials: 'include',
        }).catch((error) => {
            console.log("Not properly authenticated",error);
        });

        if(response){
            if(response.status == 200){
                let jsonData = await response.json();

                if(jsonData != "")
                    if(!storeDataAndContinue(jsonData)) return;
            }
        }

    }

    const redirectToGoogleSSO = async () => {
        let timer = null; 
        const googleLoginUrl = `${BASE_URL}${PORT}/login/google`;
        const newWindow = window.open(googleLoginUrl,"_blank","width=500,height=600");
        if(newWindow) {
            timer = setInterval(() => {
                if(newWindow.closed){
                    fetchAuthUser();
                    if(timer) clearInterval(timer);
                }
            },500);
        }
    }

    const handleMatchPassword = (e) => {
        userData[e.target.name] = e.target.value;
    
        if (userData.password != userData.repeat_password){
            setNoMatchPassword(true);
            return;
        }
        setNoMatchPassword(false);
    }

    return(
        <div className="loginPaddingDiv">
            
            <div className="loginCard smaller-height-login-card">
                <div className="container-login-image sign-up-image-container">
                    <img src={require("../../resources/3d-render-little-girl-with-pink-bow-her-head.jpg")} alt="logo"/>
                    {/* <img src={require("../../resources/cute-young-businesswoman-working-with-laptop-video-conference-online-communication-3d-illustration-cartoon-character.jpg")} alt="logo"/> */}
                    {/* <img src={require("../../resources/test/9886986.jpg")} alt="logo"/> */}
                </div>
                <div className="grid-blocks">
                        <div className="flex-end-container header-login">
                            <NavLink to="/login">
                                Login
                            </NavLink>
                            <NavLink to={ from }>
                                <div className="close-login">
                                    <div className="left-cross-part"></div>
                                    <div className="right-cross-part"></div>
                                </div>
                            </NavLink>
                        </div>    
                    <form action="" onSubmit={login} className="formLogin" autoComplete='off'>
                        <p className="login-paragraph" >Sign Up</p>
                        {(error.errorAuth || error.errorServer) && errorMsg()}
                        <div className="ContInpPlaceholder ContInpPlaceholderLog">
                            <input autoComplete='off' ref={nameInput} id="user" className="no-padding inputLogin" onChange={handlerInputs} onInput={(e) => handleNotBlank(e.target)} name="first_name" required ></input>
                            <label className="placeholder" htmlFor="user">First Name</label>
                            { <UserSvg/> } 
                        </div>
                        <div className="ContInpPlaceholder ContInpPlaceholderLog">
                            <input autoComplete='off' ref={emailInput} id="email" className="no-padding inputLogin" onChange={handlerInputs} onInput={(e) => handleEmail(e.target)} name="email" required ></input>
                            <label className="placeholder" htmlFor="email">Email</label>
                            { <EmailSvg/> } 
                        </div>
                        <div className={`ContInpPlaceholder ContInpPlaceholderLog ${noMatchPassword ? "error" : ""}`}>
                            <input autoComplete='off' ref={passwordInput} id="password" className='no-padding inputLogin'  onInput={handleMatchPassword} onChange={handlerInputs} name="password" type="password" required></input>
                            <label className="placeholder" htmlFor="password">Password</label>
                            { <PasswordSvg/> }
                        </div>
                        <div className={`ContInpPlaceholder ContInpPlaceholderLog ${noMatchPassword ? "error" : ""}`}>
                            <input autoComplete='off' ref={passwordRepeatInput} id="repeat_password" className='no-padding inputLogin'  onInput={handleMatchPassword} onChange={handlerInputs} name="repeat_password" type="password" required></input>
                            <label className="placeholder" htmlFor="repeat_password">Repeat Password</label>
                            { <PasswordSvg/> }
                        </div>
                        <button className="buttonLogin" type="reset" onClick={login}>Done</button>
                    </form>
                    <div className="flex-container flex-column continue-with-container">
                        <div className="flex-container">
                            Continue with
                        </div>
                        <div className="options-to-continue-container">
                            <div className="options-to-continue" onClick={redirectToGoogleSSO}>
                                <GoogleSvg />
                            </div>
                            <div className="options-to-continue" onClick={redirectToGoogleSSO}>
                                <MacSvg />
                            </div>
                            <div className="options-to-continue facebook-container" onClick={redirectToGoogleSSO}>
                                <FacebookAlternativeSvg />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}