import { useContext, useState } from "react";
import Question from "../../Question";
import { ApiContext } from "../../ApiContext";
import { NavLink } from "react-router-dom";
import SearchBar from "../../SearchBar";

const HowToReachUs = () => {

    const { COMPANY_PHONE } = useContext(ApiContext);
    const [matchedQuestions,setMatchedQuestions] = useState([]);

    return(
        <div className="section-container flex-container flex-column">
            
            <SearchBar matchedQuestions={matchedQuestions} setMatchedQuestions={setMatchedQuestions}/>

            <div className="section-content flex-container left-alignment section">
                <span className="main-title">
                    Help - How to reach us
                </span>
            </div>

            <div className="content-questions section-content">
            {
                matchedQuestions && matchedQuestions.length ? 
                matchedQuestions.map((d,index) => {
                    return <Question key={index} title={d["question"]} paragraphToShow = {d["answer"]}/>
                }) :
                <>
                    <Question title="Phone" paragraphToShow = "Call us at 305-527-0300 for immediate assistance. Our team is available during business hours to help with any inquiries or issues."/>
                    <Question title="Email" paragraphToShow = "You can reach us at littlegigglesbabysitter@gmail.com. Feel free to send us your questions or concerns, and we will respond as quickly as possible."/>
                    <Question title="Meeting" paragraphToShow = "If you prefer to discuss matters in person, please contact us to schedule a meeting. We can arrange a time that suits you for a discussion."/>
                </> 
            }
                
            </div>


            <div className="section-separator section-content-alt section-separator full-width"></div>


            <div className="container-contact-form">
                <div className="self-left-align flex-container section-content contact-form-main-content flex-column left-alignment">
                    <span className='contact-text full-width'>If you cannot find the answer to your questions on this page, please feel free to <NavLink to={`tel:${COMPANY_PHONE}`} className="pink-text">contact us</NavLink> via the contact form with your query.</span>
                    <NavLink to="/customer-contact-form" className="contact-form-button self-left-align rounded-pink-button standard-pink-button">
                        <div className="rounded-pink-button standard-pink-button-inner">Contact Form</div>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default HowToReachUs;