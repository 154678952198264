import '../style-sheets/Services.css';

const Services = () => {

    return(
        <div className="section-container pre-reservation-main-container flex-container flex-column">
            {/* <div className="about-header">
                <span className='section-img-title'>About us</span>
            </div> */}
            
            <div className='section-content-alt medium-paragraph full-width'>
                At <span className="pink-text">Little Giggles</span>, we specialize in finding the perfect
                babysitter for your child. We carefully select candidates and evaluate them with you to ensure they meet your expectations.
                We conduct reference checks and background screenings to guarantee the best care for your child. We're here to help you find the ideal caregiver for your little one!
            </div>
                

            <div className="section-content-alt section-separator section-separator-alternative full-width"></div>

            <div className="section-content-alt very-extra-gap top-alignment">
                
                
                <div className="flex-container flex-column">
                    <div className="list-title-alt self-left-align">
                        SERVICES :
                    </div>
                    <div className="flex-container full-width flex-column list2">
                        <div className="list-title self-left-align">
                            BABYSITTER
                        </div>
                        <div className="grid-2-columns extra-gap full-width list-categories">
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Traveling </span>
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Birthday Parties </span>
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Part Time/ Full Time </span>
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Hotels </span> 
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Live in/ live out  </span> 
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Temporary </span> 
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Hourly </span> 
                            </div>
                        </div>
                    </div>
                    <div className="full-width flex-container flex-column list2">
                        <div className="list-title self-left-align">
                            NURSE
                        </div>
                        <div className="grid-2-columns extra-gap full-width list-categories">
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>BabyNurse </span>
                            </div>
                            <div className='list-element grid-two-columns-alt left-alignment full-width'>
                                <div className="small-ball"></div>
                                <span>Nursing Overnight </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Services;