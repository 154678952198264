import './App.css';
import './style-sheets/General.css';
import './style-sheets/General_2.css';
import './style-sheets/Cards.css';
import { useNavigate } from "react-router-dom";
import { RouteHandler } from './components/RouteHandler';
import { getCookie } from "./utils/utils";
import { useEffect } from 'react';
import { ApiContext } from './components/ApiContext';

function App() {
  const navigate = useNavigate();
  const authToken = getCookie('auth_token');
  const isAuth = authToken !== null;
  // useEffect(() => {
  //   if(!isAuth){
  //     navigate("/");
  // }
  // },[isAuth,navigate])
  
  return (
    <ApiContext.Provider value={
      {
        BASE_URL : "http://localhost:",
        PORT: 5000,
        COMPANY_FACEBOOK_URL:"https://www.facebook.com/profile.php?id=100013558901093",
        COMPANY_YOUTUBE_URL:"https://www.facebook.com/profile.php?id=100013558901093",
        COMPANY_INSTAGRAM_URL:"https://www.instagram.com/littlegiggles_babysittingco?igsh=MXRqZmo1OHc5bHhxag%3D%3D&utm_source=qr",
        COMPANY_PHONE:"+13055270300",
        COMPANY_EMAIL:"littlegigglesbabysitter@gmail.com",
        QUESTIONS : {
          "What services does Little Giggles Babysitting Company offer?" : "We offer babysitting and nursing services with highly trained caregivers, including basic medical assistance and general childcare.",
          "How can I hire a babysitter?" : "You can contact us by phone at 305-527-0300 or by email at littlegigglesbabysitter@gmail.com. You may also schedule a meeting to discuss your needs and select the right babysitter.",
          "What requirements do caregivers need to meet?" : "All our caregivers must present valid identification, such as a passport, driver's license, and proof of residence. They undergo alcohol and drug tests and thorough background checks. All caregivers hold CPR certification, covering first aid and emergency response.",
          "How do you ensure the safety of the children?" : "We ensure that our caregivers are fully trained and vetted. We maintain open communication with parents and provide detailed information about each caregiver.",
          "What if I have a problem with a babysitter?" : "If you experience any issues, please contact us immediately. We are here to resolve any concerns and ensure a positive experience. You can call us at 305-527-0300 or email us at littlegigglesbabysitter@gmail.com.",
          "What is your cancellation policy?" : "Our cancellation policy may vary depending on the type of service. Please contact us directly for specific details and any potential cancellation fees.",
          "How do you protect my family's privacy?" : "We handle all personal information with the utmost confidentiality and security. Caregivers are also committed to respecting and protecting your family's privacy during their time in your home.",
          
          "Phone" : "Call us at 305-527-0300 for immediate assistance. Our team is available during business hours to help with any inquiries or issues.",
          "Email" : "You can reach us at littlegigglesbabysitter@gmail.com. Feel free to send us your questions or concerns, and we will respond as quickly as possible.",
          "Meeting" : "If you prefer to discuss matters in person, please contact us to schedule a meeting. We can arrange a time that suits you for a discussion.",
        }
      }} >
      <div className="App">
        <RouteHandler navigate={navigate}/>
      </div>
    </ApiContext.Provider>
  );
}

export default App;
