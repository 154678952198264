import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ReactQuillWrapper = ({ name, onChange }) => {

  let current_input_quill = useRef(null);
  const [value,setValue] = useState("");

  const handleChange = (value) => {
    if(onChange != undefined){
      onChange({"target" : current_input_quill.current})
    }

    setValue(value);

};

  return (
    <>
      <ReactQuill
        theme="snow"
        onChange={handleChange}  
        value={value}
      />
        <input type="hidden" name={name} ref={current_input_quill}  value={value}/>
    </>
  );
}

export default ReactQuillWrapper;