import {configureStore} from "@reduxjs/toolkit"
import {customAlertReducer} from "./reducers/customAlertReducer.js"
import { userDataReducer } from "./reducers/userDataReducer.js";

const store = configureStore({
    reducer:{
        customAlert:customAlertReducer,
        userDataReducer:userDataReducer
    },
});
export default store;