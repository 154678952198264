import '../style-sheets/ContactCustomerForm.css';
import { useContext, useState } from 'react';
import { ApiContext } from './ApiContext';
import { NavLink } from 'react-router-dom';
import { UserSvg } from './svg_components/UserSvg';
import { PhoneSvg } from './svg_components/PhoneSvg';
import { EmailSvg } from './svg_components/EmailSvg';
import ReactQuillWrapper from './ReactQuillWrapper';
import { handleFormChange, mapDataAndCheckValid } from '../utils/utils';
import { Alert } from './alert_components/Alert/Alert';

const ContactCustomerForm = () => {
    const { COMPANY_PHONE,BASE_URL,PORT } = useContext(ApiContext);

    const [formData,setFormData] = useState({
        "first-name": {"value" : "","error" : false,"type" : "character","required" : true},
        "last-name": {"value" : "","error" : false,"type" : "character","required" : true},
        "phone-number": {"value" : "","error" : false,"type" : "number","required" : true},
        "email-address": {"value" : "","error" : false,"type" : "email","required" : true},
        "question" :{"value" : "","error" : false,"type" : "character","required" : true},
    });


    const handleFormSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let customData = {}

        let canSend = true;
        let errorFields = [];
        let firstErrorElementName = "";

        let dataKeys = Object.keys(formData);
        for(let i =0;i < dataKeys.length;i++){

            if(formData[dataKeys[i]]["value"].replaceAll(/(&nbsp;|<([^>]+)>)/ig,"") == ""){
                formData[dataKeys[i]]["error"] = true;
                
                if(firstErrorElementName == ""){
                    firstErrorElementName = dataKeys[i];
                }

                canSend = false;
            }else{
                formData[dataKeys[i]]["error"] = false;
                customData[dataKeys[i]] = formData[dataKeys[i]]["value"];
            }
        }

        if(canSend){

            let response = await fetch(`${BASE_URL}${PORT}/question`,{
                method:"POST",
                headers:{ "Content-Type": "application/json"},
                body:JSON.stringify(customData)
            });

            let jsonData = {};
            if(response.status == 200){
                jsonData = await response.json();
            }
            

            if(response.status == 200 && jsonData?.code == 200){
                Alert.fire({    
                    "text":`Question successfully sended`,
                    "type":"success"
                })    
            }else{
                Alert.fire({    
                    "text":`Something was wrong, try again later`,
                    "type":"warning"
                })
            }
        }else{
            let fieldsNames = "";
            errorFields.map((d,index) => {
                let label = document.querySelector(`label[for="${d}"]`);
                let spanCustomSelect = document.querySelector(`span[name='${d}']`);

                
                if(label){
                    fieldsNames += label.innerText + (index == d.length ? '' : ',');
                }else{
                    console.log(d)
                    fieldsNames += spanCustomSelect.innerText + (index == d.length ? '' : ',');
                }
            })
            // Alert.fire({
            //     "text":`Check the next fields <br> ${fieldsNames}`,
            //     "type":"error"
            // })
            Alert.fire({
                "text":`Check the fields`,
                "type":"error"
            })
            if(document.getElementsByName(firstErrorElementName)){
                document.getElementsByName(firstErrorElementName)[0].scrollIntoView();
            }else{
                console.warn("The field with the first error could'nt be found for get element by name -> field name:",firstErrorElementName)
            }
        }
    }

    const handleFormFieldChange = (e) => {
        handleFormChange(e.target,formData,setFormData);
    }

    return(
        <div className="section-container section-container-customer-form flex-container flex-column">
            <div className="customer-contact-form-header header-image">
                <span className='section-img-title'>Contact customer service</span>
            </div>

            <div className="medium-percent-margin-top customer-contact-form-starter-container section-content flex-container full-width">
                <div className="customer-contact-form-starter section-customer-contact-form-header">
                    <h3>Frequently Asked  Questions</h3>
                    {/* pink-text */}
                    <p className='medium-paragraph gray-color full-width'>Do you have a question for Little Giggles? First check if the answer to your question can be found below:</p>
                    <NavLink to="/help/faq" className="medium-paragraph pink-text">Frequently Asked  Questions</NavLink>
                </div>
            </div>

            <div className="customer-contact-form-starter-container section-content self-center-align flex-container flex-column full-width">
                <div className="customer-contact-form-starter">
                    <h3>Contact form with Customer Service</h3>
                    {/* pink-text */}
                    <p className='medium-paragraph gray-color full-width'>If you have any questions about the Little Giggles platform and cannot find the answer on this website, please use the contact form to contact us.</p>
                </div>
                <div className="grid-2-columns full-width extra-gap medium-margin-top">
                    <div className={`ContInpPlaceholder babysitter-form ${formData['first-name']['error'] ? 'error-alt' : ''}`}>
                        <UserSvg/>
                        <input onChange={handleFormFieldChange} id="first-name" className="greater-width blue-border small-height left-alignment form-input" value={formData['first-name']['value']} name="first-name" type="text" required/>
                        <label className="placeholder form-label" htmlFor="first-name">First Name <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formData['last-name']['error'] ? 'error-alt' : ''}`}>
                        <UserSvg/>
                        <input onChange={handleFormFieldChange} id="last-name" className="greater-width blue-border small-height left-alignment form-input" value={formData['last-name']['value']} name="last-name" type="text" required/>
                        <label className="placeholder form-label" htmlFor="last-name">Last Name <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formData['phone-number']['error'] ? 'error-alt' : ''}`}>
                        <PhoneSvg/>
                        <input onChange={handleFormFieldChange} id="phone-number" className="greater-width blue-border small-height left-alignment form-input" value={formData['phone-number']['value']} name="phone-number" type="text" required/>
                        <label className="placeholder form-label" htmlFor="phone-number">Phone Number <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formData['email-address']['error'] ? 'error-alt' : ''}`}>
                        <EmailSvg/>
                        <input onChange={handleFormFieldChange} id="email-address" className="greater-width blue-border small-height left-alignment form-input" value={formData['email-address']['value']} name="email-address" type="text" required/>
                        <label className="placeholder form-label" htmlFor="email-address">Email Address <span className='pink-text'>*</span></label>
                    </div>
                    <div className="no-padding contact-form-quill-container grid-self-2-columns flex-container flex-column full-width">
                        <div className="flex-container left-alignment small-height">
                            <h2 className="placeholder form-label" htmlFor="reservation-date">Your Question</h2>
                        </div>
                        <div className={`full-width ${formData['question']['error'] ? 'error-alt' : ''}`}>
                            <ReactQuillWrapper name={"question"} onChange={handleFormFieldChange}/>
                        </div>
                    </div>
                    <input type="submit" onClick={handleFormSubmit} className="custom-submit-contact-form standard-pink-button submit-button self-left-align" value="Submit"/>
                </div>
            </div>
        </div>
    );
}
export default ContactCustomerForm;