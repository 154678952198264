import React, { useEffect, useRef, useState } from 'react';
import Flatpickr from "react-flatpickr";


const ReactFlatpickrWrapper = ({ values,index,onOpen,name,onChange }) => {
  const input_flatpickr = useRef(undefined);

  useEffect(() => {
    let label = document.querySelector(`.date-birth-label-${index + 1}`);
    if(values == "" || values == undefined && label)
      label.classList.remove("flatpickr-active")
  },[values])

  const handleChange = (value) => {
    if(onChange != undefined) onChange({target:input_flatpickr.current.node});
  };

// options={{minDate: minDate}}
  return (
    <Flatpickr name={name} value={values || ""} ref={input_flatpickr} onOpen={onOpen}
    onChange={handleChange}/>
  );
}

export default ReactFlatpickrWrapper;