import '../style-sheets/About.css';
import security_image from "../resources/about-images/stand-for-safety.jpg";
import privacy_image from "../resources/about-images/stand-for-privacy.jpg";
import transparency_image from "../resources/about-images/stand-for-transparency.jpg";
import team8 from "../resources/about-images/team8.jpg";
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { ApiContext } from './ApiContext';

const About = () => {
    const { COMPANY_PHONE } = useContext(ApiContext);

    return(
        <div className="section-container">
            <div className="about-header header-image">
                <span className='section-img-title'>About us</span>
                
            </div>
            <div className="about-starter-container flex-container full-width">
                <div className="about-starter section-about-header section-content-alt">
                    <h3>Welcome to Little Giggles Babysitting Company</h3>
                    {/* pink-text */}
                    <p className='medium-paragraph gray-color full-width'>At <span className="pink-text">Little Giggles Babysitting Company</span>, we understand that trust is crucial when it comes to the safety and well-being of your children. We are a team of highly trained professionals dedicated to providing top-notch babysitting and overnight care services with the highest level of excellence and commitment.</p>
                </div>
            </div>
            <div className="section-separator full-width"></div>
            
            <div className="section-content-alt extra-gap flex-container top-margin-center container-about flex-column">
                <div className="flex-container flex-column">
                    <span className="title-2">Why Choose Us?</span>
                    <div className="small-margin-top section-separator-alternative section-separator full-width"></div>
                </div>
                <div className="grid-4-columns">
                    <div className="card no-padding card-alternative-2">
                        <div className="flex-container flex-column small-padding">
                            <span className="card-type">Elite Professionals:</span>
                            <div className="small-margin-top section-separator-alternative section-separator full-width"></div>
                            <span className='gray-color'>Our team consists of expert caregivers who have been meticulously selected and trained to ensure the highest quality of care. Each member undergoes a thorough vetting and training process to meet our strict standards.</span>
                        </div>
                    </div>
                    <div className="card no-padding card-alternative-2">
                        <div className="flex-container flex-column small-padding">
                            <span className="card-type">Personalized Attention:</span>
                            <div className="small-margin-top section-separator-alternative section-separator full-width"></div>
                            <span className='gray-color'>At Little Giggles, we don't just care for your children; we become a part of their daily routine. We strive to understand and tailor our services to the specific needs and preferences of each family, providing a unique and personalized experience.</span>
                        </div>
                    </div>
                    <div className="card no-padding card-alternative-2">
                        <div className="flex-container flex-column small-padding">
                            <span className="card-type">Commitment to Safety:</span>
                            <div className="small-margin-top section-separator-alternative section-separator full-width"></div>
                            <span className='gray-color'>Our team consists of expert caregivers who have been meticulously selected and trained to ensure the highest quality of care. Each member undergoes a thorough vetting and training process to meet our strict standards.</span>
                        </div>
                    </div>
                    <div className="card no-padding card-alternative-2">
                        <div className="flex-container flex-column small-padding">
                            <span className="card-type">Quality Overnight Care:</span>
                            <div className="small-margin-top section-separator-alternative section-separator full-width"></div>
                            <span className='gray-color'>We offer overnight care services that ensure your children are well looked after while you rest. Our team is trained to handle all nighttime needs with professionalism and care.</span>
                        </div>
                    </div>
                </div>

                <div className="flex-container full-width">
                        <p className="medium-paragraph full-width">
                            At <span className="pink-text">Little Giggles Babysitting Company</span>, we take pride in delivering a service that not only meets but exceeds expectations. Our goal is to give you the reassurance that your children are in the best hands.
                            Let us be your partner in caring for your little ones. Because to us, every family is special and every child deserves the best care possible.
                            <NavLink to={`tel:${COMPANY_PHONE}`} className="pink-text">Contact us</NavLink> today and discover how we can become a part of your family!
                        </p>
                    </div>
            </div>

            <div className="section-separator full-width"></div>

            {/* <div className="about-page-cards-main-content flex-container flex-column">
                <div className="section-content">
                    <h2 className='medium-font-size'>Meet the team</h2>
                </div>
                <div className="team-card">
                    <div className='team-card-text'>
                        "I found a Supersitter for my children through Babysits and I use what I learn from my own experience to improve the platform."
                        <br/><br/>
                        -Example Author-
                    </div>
                    <img src={team8} className="team-card-img" alt=""/>
                </div>
                
            </div>
            <div className="section-separator full-width"></div> */}

            <div className="about-page-cards-main-content flex-container flex-column">
                <div className="section-content-alt flex-container flex-column">
                    <h2 className='medium-font-size'>What we stand for</h2>
                    <div className="grid-3-columns max-width-about-cards">

                        <div className="card card-alt-3 no-padding">
                            <img src={security_image} className="card-image" alt=""/>
                            <div className="card-grid-container small-padding">
                                <span className="center-align card-type">Security</span>
                                <div className="small-margin-top section-separator full-width"></div>
                                <span className='gray-color'>The safety of your children is our top priority. To ensure a secure environment, each babysitter must present valid identification, such as a passport, driver's license, and proof of residence. Additionally, we conduct alcohol and drug tests and thorough background checks to ensure that each caregiver meets the highest standards of integrity and responsibility. All of our caregivers hold CPR certification, which covers first aid and emergency response skills.</span>
                                <NavLink to="/security">
                                    <h4 className='center-align'>Learn more</h4>
                                </NavLink>
                            </div>
                        </div>


                        <div className="card card-alt-3 no-padding">
                            <img src={transparency_image} className="card-image" alt=""/>
                            <div className="card-grid-container small-padding">
                                <span className="center-align card-type">Transparency</span>
                                <div className="small-margin-top section-separator full-width"></div>
                                <span className='gray-color'>Open and clear communication is essential for building trust. We provide comprehensive information about each babysitter's experience and qualifications, including references and relevant certificates. We also maintain an open-door policy so you can ask questions and express any concerns. We want you to feel fully informed and comfortable with your choice of caregiver for your children.</span>
                            </div>
                        </div>

                        <div className="card card-alt-3 no-padding">
                            <img src={privacy_image} className="card-image" alt=""/>
                            <div className="card-grid-container small-padding">
                                <span className="center-align card-type">Privacy</span>
                                <div className="small-margin-top section-separator full-width"></div>
                                <span className='gray-color'>We respect and protect your family's privacy. We handle all personal information with the utmost confidentiality and implement security measures to protect your data. Additionally, each babysitter is committed to safeguarding and respecting the family's privacy, ensuring that information and activities related to the care of your children remain confidential.</span>
                                <NavLink to="/terms-conditions-and-privacy">
                                    <h4 className='center-align'>Learn more</h4>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
export default About;