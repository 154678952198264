const initialState = {
    user: undefined
};
  
export const userDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_DATA_GOOGLE':
        return {
            ...state,
            user: action.payload.user
        };
        default:
        return state;
    }
};
