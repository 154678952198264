import '../../style-sheets/Help.css';
import search_svg from "../../resources/svg/search.svg";
import { NavLink } from 'react-router-dom';
import { ApiContext } from '../ApiContext';
import { useContext, useState } from 'react';
import { RightArrowSvg } from '../svg_components/RightArrowSvg';
import SearchBar from '../SearchBar';
import Question from '../Question';

const Help = () => {

    const { COMPANY_PHONE } = useContext(ApiContext);
    const [matchedQuestions,setMatchedQuestions] = useState([]);
    
    return(
        <div className="section-container">
            <div className="help-header header-image">
                <span className='section-img-title'>How can we help you?</span>
                
            </div>
            <div className="flex-container flex-column very-extra-gap main-content-help">
                <div className="about-starter section-content">
                    <p className='medium-paragraph'>If you have any questions or need assistance, we are here to help. Our support team is available to address your concerns and ensure you have a smooth experience with Little Giggles Babysitting Company.</p>
                </div>
                <div className="grid-2-columns section-content">
                    <NavLink to="/about" className="flex-container card left-alignment">
                        <div className="flex-container flex-column">
                            <span className="card-main-title full-width">Experience You Can Trust</span>
                            <span className="card-subtitle full-width">Meet our team of childcare experts dedicated to finding your perfect nanny match.</span>
                        </div>
                        <RightArrowSvg/>
                    </NavLink>

                    <NavLink to="/security" className="flex-container card left-alignment">
                        <div className="flex-container flex-column">
                            <span className="card-main-title full-width">Trust and security</span>
                            <span className="card-subtitle full-width">Find out how we can help you hire a babysitter with confidence.</span>
                        </div>
                        <RightArrowSvg/>
                    </NavLink>
                </div>
                    
                {/* <SearchBar title="Find all the answers to your questions about our platform here!" matchedQuestions={matchedQuestions} setMatchedQuestions={setMatchedQuestions}/> */}

                {/* {
                    matchedQuestions && matchedQuestions.length ?
                    
                    <div className="content-questions section-content"> 
                        { 
                            matchedQuestions.map((d,index) => {
                                return <Question key={index} title={d["question"]} paragraphToShow = {d["answer"]}/>
                            })
                        }
                    </div> :
                    <div className="grid-2-columns section-content">
                        <NavLink to="how-to-reach-us" className="flex-container flex-column card left-alignment card-alt">
                            <span className="card-main-title full-width">How to Reach Us</span>
                        </NavLink>

                        <NavLink to="faq" className="flex-container flex-column card left-alignment card-alt">
                            <span className="card-main-title full-width">Frequently Asked Questions</span>
                        </NavLink>
                        
                        
                    </div>
                } */}

                {/* TEMPORAL | */}
                {/*          V */}

                <div className="content-questions section-content">
                    <Question title="What services does Little Giggles Babysitting Company offer?" paragraphToShow = {`We offer babysitting and nursing services with highly trained caregivers, including basic medical assistance and general childcare.`}/>
                    <Question title="How can I hire a babysitter?" paragraphToShow = {`You can <a href='tel: ${COMPANY_PHONE}' class="highlight-text">contact us</a> by phone at 305-527-0300 or by email at littlegigglesbabysitter@gmail.com. You may also schedule a meeting to discuss your needs and select the right babysitter.`}/>
                    <Question title="What requirements do caregivers need to meet?" paragraphToShow = {`All our caregivers must present valid identification, such as a passport, driver's license, and proof of residence. They undergo alcohol and drug tests and thorough background checks. All caregivers hold CPR certification, covering first aid and emergency response.`}/>
                    <Question title="How do you ensure the safety of the children?" paragraphToShow = {`We ensure that our caregivers are fully trained and vetted. We maintain open communication with parents and provide detailed information about each caregiver.`}/>
                    <Question title="What if I have a problem with a babysitter?" paragraphToShow = {`If you experience any issues, please <a href='tel: ${COMPANY_PHONE}' class="highlight-text">contact us</a> immediately. We are here to resolve any concerns and ensure a positive experience. You can call us at 305-527-0300 or email us at littlegigglesbabysitter@gmail.com.`}/>
                    <Question title="What is your cancellation policy?" paragraphToShow = {`Our cancellation policy may vary depending on the type of service. Please <a href='tel: ${COMPANY_PHONE}' class="highlight-text">contact us</a> directly for specific details and any potential cancellation fees.`}/>
                    <Question title="How do you protect my family's privacy?" paragraphToShow = {`We handle all personal information with the utmost confidentiality and security. Caregivers are also committed to respecting and protecting your family's privacy during their time in your home.`}/>
                    <Question title="Are there any special requirements for caregivers of children with specific needs?" paragraphToShow = {`If your child has special needs, please inform us when making your request. We will do our best to assign a caregiver with the appropriate experience to meet those needs.`}/>
                    <Question title="Phone" paragraphToShow = {`Call us at 305-527-0300 for immediate assistance. Our team is available during business hours to help with any inquiries or issues.`}/>
                    <Question title="Email" paragraphToShow = {`You can reach us at littlegigglesbabysitter@gmail.com. Feel free to send us your questions or concerns, and we will respond as quickly as possible.`}/>
                    <Question title="Meeting" paragraphToShow = {`If you prefer to discuss matters in person, please <a href='tel: ${COMPANY_PHONE}' class="highlight-text">contact us</a> to schedule a meeting. We can arrange a time that suits you for a discussion.`}/>
                </div>
                
                <div className="section-separator section-content-alt section-separator full-width"></div>
                
                {/* <NavLink to={`tel:${COMPANY_PHONE}`} className="pink-text">contact us</NavLink> */}
                <div className="container-contact-form">
                    <div className="self-left-align flex-container section-content contact-form-main-content flex-column left-alignment">
                        <span className='contact-text full-width'>If you cannot find the answer to your questions on this page, please feel free to contact us via the contact form with your query.</span>
                        <NavLink to="/customer-contact-form" className="contact-form-button self-left-align rounded-pink-button standard-pink-button">
                            <div className="rounded-pink-button standard-pink-button-inner">Contact Form</div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Help;