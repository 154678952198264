import '../style-sheets/Docs.css';
// import search_svg from "../resources/svg/search.svg";
// import trusted_babysitter from "../resources/docs-images/3d-render-little-girl-with-pink-bow-her-head.jpg";
// import oceans_protection from "../resources/docs-images/cómo-los-niños-pueden-ayudar-a-proteger-los-océanos-3631-1679498474.jpg";
// import be_father from "../resources/docs-images/lo-que-significa-ser-papá-según-10-padres-alrededor-del-mundo-3625-1677754518.jpg";
// import bilingual_child_advice from "../resources/docs-images/consejos-para-criar-un-niño-bilingüe-3600-1665739252.jpg";
// import security_in_summer from "../resources/docs-images/seguridad-en-verano-para-niños-los-consejos-y-hechos-más-importante-3568-1657006898.jpg";
// import internet_for_children from "../resources/docs-images/aprende-sobre-la-seguridad-en-internet-para-niños-3453-1652869889.jpg";
// import help_children_with_dyslexia from "../resources/docs-images/cómo-puede-ayudar-la-formación-online-a-niños-con-dislexia-3395-1649665390.jpg"
// import fun_crafts from "../resources/docs-images/manualidades-divertidas-y-educativas-de-primavera-para-niños-3311-1648128986.jpg"

const Docs = () => {

    return(
        <div className="section-container">
            {/* <div className="docs-header header-image">
                <span className='section-img-title'>Recursos para la comunidad de Little Giggles Babysitting Company</span>
                
            </div>
            <div className="flex-container flex-column very-extra-gap main-content-help">
                <div className="search-bar-container">
                    <div className="search-bar-main-body">
                        <input type="text" className="search-bar" />
                        <div className="search-bar-icon">
                            <img src={search_svg} alt="search bar icon magnifying glass"/>
                        </div>
                    </div>
                </div> */}
                {/* possibly component here */}
                {/* <div className="grid-docs-content">
                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={oceans_protection} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Acción climática</span>
                        <h3 className="full-width">Cómo los niños pueden ayudar a proteger los océanos</h3>
                        <span className="card-short-description">¡Anima a tu hijo a aprender más sobre los océanos y cómo podemos ayudar a protegerlos con estos consejos!</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={trusted_babysitter} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Consejos For families</span>
                        <h3 className="full-width">¿Cómo encontrar una niñera de confianza?</h3>
                        <span className="card-short-description">¿Buscas una niñera? Cada vez más padres buscan en internet una niñera adecuada. Pero, ¿cuál es la mejor manera de encontrar una niñera? Lee nuestros 10 consejos sobre lo que debes hacer cuando busques una niñera de confianza.</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={be_father} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Consejos For families</span>
                        <h3 className="full-width">Lo que significa ser papá, según 10 padres alrededor del mundo</h3>
                        <span className="card-short-description">Nos hemos preguntado qué significa para los papás ser padre en este 2020 y hemos hablado con varios padres alrededor del mundo sobre su experiencia personal.</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={bilingual_child_advice} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Consejos For families</span>
                        <h3 className="full-width">Consejos para criar un niño bilingüe</h3>
                        <span className="card-short-description">La exposición a más de un idioma puede ser increíblemente beneficiosa para tu hijo a medida que el mundo se globaliza progresivamente. Además, la capacidad de hablar más de un idioma mejora el desarrollo intelectual de tu hijo y aumenta.</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={security_in_summer} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Consejos para Canguros • Consejos For families</span>
                        <h3 className="full-width">Seguridad en Verano para Niños: Consejos y Hechos más Importantes</h3>
                        <span className="card-short-description">Los mejores consejos para un verano con niños; ¡cómo estar seguro bajo el sol, en la playa o en la piscina y sobre todo divertirse este verano!</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={internet_for_children} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Consejos For families</span>
                        <h3 className="full-width">Aprende sobre la seguridad en Internet para niños</h3>
                        <span className="card-short-description">La seguridad en Internet es extremadamente importante, especialmente para los niños. Discutimos el tema con expertos y encuestamos a los miembros de nuestra comunidad para brindarle información actualizada sobre cómo proteger a los niños.</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={help_children_with_dyslexia} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Consejos For families</span>
                        <h3 className="full-width">¿Cómo puede ayudar la formación online a niños con dislexia?</h3>
                        <span className="card-short-description">¿Alguno de tus hijos tiene dislexia y estás teniendo problemas para encontrar el método de aprendizaje perfecto para ayudarlos? ¡Podríamos tener la solución! Hoy hablaremos sobre cómo la nueva tendencia, el aprendizaje online, puede ser.</span>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2">
                        <img src={fun_crafts} className="card-image" alt ="3d-render-little-girl-with-pink-bow-her-head"/>
                        <span className="card-type self-left-align">Actividades de cuidado de niños • Manualidades</span>
                        <h3 className="full-width">Manualidades divertidas y educativas de primavera para niños</h3>
                        <span className="card-short-description">¿Has notado los cambios sutiles en la Naturaleza? Los días se hacen más largos y cálidos y el suelo empieza a vestirse de flores. Es hora de la primavera y todos los sentimientos confusos que la acompañan. ¡Aprende a cocinar panecillos s...¿Has notado los cambios sutiles en la Naturaleza? Los días se hacen más largos y cálidos y el suelo empieza a vestirse de flores. Es hora de la primavera y todos los sentimientos confusos que la acompañan. ¡Aprende a cocinar panecillos s.</span>
                    </div>

                </div>

                <div className="slider-section full-width flex-container">
                    <div className="slider-main-container">
                        <div className="main-title">
                            Ideas, consejos y actividades para niños
                        </div>
                    </div>
                </div>
                <div className="container-contact-form">
                    <div className="self-center-align flex-container section-content contact-form-main-content left-alignment">
                        <span className='contact-text'>Unete a la comunidad de canguros.</span>
                        <div className="rounded-pink-button standard-pink-button flex-container">
                            <div className="rounded-pink-button standard-pink-button-inner">Become a babysitter</div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
export default Docs;