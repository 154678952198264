import { useState } from "react";
import { PrintSvg } from "../components/svg_components/PrintSvg";
import "../style-sheets/TermsAndPrivacyPolicy.css";

export const TermsAndPrivacyPolicy = () => {
    const [activeElement,setActiveElement] = useState("terms-conditions");

    return(
        <div className="section-container terms-section flex-container flex-column">
            <div className="flex-container flex-column section-content">
                <ul className="nav-header full-width">
                    <li className={`nav-item ${activeElement == "terms-conditions" ? "active-item-header" : ""}`} onClick={() => setActiveElement("terms-conditions")}>Terms & Conditions</li>
                    <li className={`nav-item ${activeElement == "privacy-policy" ? "active-item-header" : ""}`} onClick={() => setActiveElement("privacy-policy")}>Privacy Policy</li>
                </ul>
                <div className="container-text">
                    {
                    activeElement == "terms-conditions" ?
                    <div className="privacy-policy-container">
                        <span className="pink-text-alt-title important-title">Little Giggles Babysitting Company Privacy Policy</span>
                        <p className="medium-paragraph-alt">
                            At Little Giggles Babysitting Company, the privacy of our customers is our top priority. We are committed to protecting the personal information we collect and using it responsibly and transparently. This policy details how we manage your personal data and your related rights.
                            <br/>
                            <br/>
                            <span className="pink-text-alt">What information do we collect?</span>
                            <br/>
                            We collect personal information when you register on our website or use our services. 
                            Contact details: Name, email, phone, address.
                            Family details: Information about your children such as age, special needs, allergies.
                            Caregiver information: Contact details, experience, references, training.
                            Preferences: Your preferences regarding childcare services.
                            Browsing data: Information about how you use our website (cookies).
                            <br/>
                            <br/>
                            <span className="pink-text-alt">How do we use your information?</span>
                            <br/>
                            Providing our services: Coordinating visits, selecting caregivers.
                            Communicating with you: Sending you updates, reminders, and offers.
                            Complying with the law: Complying with applicable laws and regulations.
                            <br/>
                            <br/>
                            <span className="pink-text-alt">Who do we share your information with?</span>
                            <br/>
                            Caregivers: So they can provide you with the service.
                            Authorities: If required by law.
                            <br/>
                            <br/>
                            <span className="pink-text-alt">How do we protect your information?</span>
                            <br/>
                            We implement security measures to protect your information from unauthorized access.
                            You can edit any errors in user creation.
                            You can request that we delete your information.
                            You can object to the processing of your data.
                            <br/>
                            <br/>
                            <span className="pink-text-alt">Changes to this policy:</span>
                            <br/>
                            We may update this policy. We will notify you of any material changes.
                            <br/>
                            <br/>
                            <span className="pink-text-alt">Contact:</span>
                            <br/>                            
                            If you have any questions, you can contact us at <span className="pink-text-alt">example@gmail.com.</span>
                        </p>
                </div>
                :
                <div className="terms-and-conditions-container">
                    <span className="pink-text-alt-title important-title">Little Giggles Babysitting Company Terms and Conditions</span>
                    <p className="medium-paragraph-alt">
                        By using this website and the services offered by Little Giggles Babysitting Company (hereinafter referred to as "the Company"), you agree to the following terms and conditions. We recommend that you read them carefully before using our website.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Services:</span>
                        <br/>   
                        The Company offers an online platform that connects parents and caregivers of children. Caregivers are interviewed by our staff to verify their capabilities.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Payment:</span>
                        <br/>   
                        Payments for childcare services will be made in person. The Company reserves the right to change fees at any time.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Indemnification:</span>
                        <br/>   
                        You agree to indemnify and hold harmless the Company, its affiliates and employees from any claim or demand arising from the use of our services.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Intellectual Property:</span>
                        <br/>   
                        All intellectual property rights in the website and its content belong to the Company.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Modification of Terms:</span>
                        <br/>   
                        The Company reserves the right to modify these terms and conditions at any time. We will notify you of any material changes. 
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Privacy:</span>
                        <br/>   
                        We encourage you to review our Privacy Policy to learn how we handle your personal information.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Applicable Law:</span>
                        <br/>    
                        These terms and conditions shall be governed by and construed in accordance with the laws of the United States of America.
                        <br/>
                        <br/>
                        <span className="pink-text-alt">Contact:</span>
                        <br/>    
                        For any questions or queries, you may contact us at <span className="pink-text-alt">example@gmail.com.</span>
                    </p>
                </div>   
                    }
                </div>
            </div>
        </div>
    ) 
}