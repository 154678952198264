import { NavLink } from "react-router-dom";
import "../style-sheets/Security.css";
import { IdentityCardSvg } from "./svg_components/IdentityCardSvg";
import { SupremeStandardsSvg } from "./svg_components/SupremeStandards";
import { ShieldSvg } from "./svg_components/ShieldSvg";
import { RequirementsBookSvg } from "./svg_components/RequirementsBookSvg";
import { SearchSvg } from "./svg_components/SearchSvg";

export const Security = () => {
    return(
        <div className="section-container">
            <div className="security-header header-image">
                <span className='section-img-title'>Trust is the key to making Little Giggles work</span>
            </div>

            <div className="section-content-alt flex-container top-margin-center flex-column">
                <div className="flex-container full-width">
                    <p className="medium-paragraph full-width full-width">
                        When finding a babysitter or babysitting job, nothing is more important than trust and safety. Therefore, 
                        the entire Babysits community needs to work together to ensure the best experience on the platform. Here is a 
                        summary of the most important steps Babysits takes and what steps you can take:
                    </p>
                </div>
                <div className="full-width grid-3-columns grid-3-columns-alt-2">
                    <div className="flex-container flex-column left-alignment card-alt-2 extra-font-security-card security-card">
                        <div className="self-left-align flex-container left-alignment">
                            <ShieldSvg/>
                        </div>
                        <span className="full-width card-main-title medium-margin-bottom">Safety at Little Giggles Babysitting Company</span>

                        <span className="card-medium-description">At Little Giggles Babysitting Company, the safety of families and babies is our top priority. We understand that the peace of mind of parents and the well-being of children depend on trust, which is why we work tirelessly to ensure a safe environment in every interaction. Our commitment is to provide quality care, backed by rigorous safety measures, so that both families and our caregivers have the best possible experience.</span>
                        <span className="important-text card-medium-description">When it comes to caring for little ones, there is no room for error. That's why we've implemented a safety system that involves both our professionals and the families. We want to ensure that everyone, from the babysitter to the parents, feels safe and protected at all times.</span>
                    </div>
                    <div className="flex-container flex-column left-alignment card-alt-2 extra-font-security-card security-card">
                        <div className="self-left-align flex-container left-alignment">
                            <IdentityCardSvg/>
                        </div>
                        <span className="full-width card-main-title medium-margin-bottom">How do we ensure safety</span>
    
                        <div className="flex-container flex-column no-padding">
                            <span className="card-medium-description">
                                1. <span className="important-text card-medium-description card-subtitle-alt-2">Background checks</span> <br/>
                                All caregivers undergo complete background checks before being hired. This includes drug and alcohol tests to ensure they are fit to care for children.
                            </span>

                            <span className="card-medium-description">
                                2. <span className="important-text card-medium-description card-subtitle-alt-2">CPR and First Aid Certification</span> <br/>
                                Every one of our caregivers is certified in CPR and First Aid. This training is essential to know how to act quickly in any emergency situation.
                            </span>
            
                            <span className="card-medium-description">
                                3. <span className="important-text card-medium-description card-subtitle-alt-2">In-person interviews</span> <br/>
                                Before starting work, all caregivers must pass an in-person interview to ensure they meet the company's standards. This step allows us to learn more about their character and skills.
                            </span>
        
                        </div>
                    </div>

                    <div className="flex-container flex-column left-alignment card-alt-2 extra-font-security-card security-card">
                        <div className="self-left-align flex-container left-alignment">
                            <RequirementsBookSvg/>
                        </div>
                        <span className="card-main-title medium-margin-bottom full-width">Requirements for caregivers on the first day of work</span>
    
                        <div className="flex-container flex-column no-padding">
                            <span className="card-medium-description">
                                1. <span className="important-text card-medium-description card-subtitle-alt-2">Official identification document</span> <br/>
                                Caregivers must present an official identification on the first day of work. This may include a driver's license, passport, or national ID card.
                            </span>

                            <span className="card-medium-description">
                                2. <span className="important-text card-medium-description card-subtitle-alt-2">Updated CPR and First Aid certification</span> <br/>
                                Make sure your CPR and First Aid certification is current and bring copies for our records.
                            </span>
            
                            <span className="card-medium-description">
                                3. <span className="important-text card-medium-description card-subtitle-alt-2">Recent drug and alcohol tests</span> <br/>
                                Before starting any job, all caregivers must have successfully passed our drug and alcohol tests. The safety of our little ones is non-negotiable.
                            </span>


                            <span className="card-medium-description">
                                4. <span className="important-text card-medium-description card-subtitle-alt-2">Commitment to safety</span> <br/>
                                Sign the Little Giggles safety agreement, where you commit to following all our safety policies and guidelines during each service you provide.
                            </span>
        
                        </div>
                    </div>


                    <div className="flex-container flex-column left-alignment card-alt-2 extra-font-security-card security-card security-card">
                        <div className="self-left-align flex-container left-alignment">
                            <SearchSvg/>
                        </div>
                        <span className="full-width card-main-title medium-margin-bottom">What can families do?</span>
    
                        <div className="flex-container flex-column no-padding">
                            <span className="card-medium-description">
                                1. <span className="important-text card-medium-description card-subtitle-alt-2">Review caregiver profiles</span> <br/>
                                We encourage you to carefully review the profiles of our caregivers and take advantage of the initial interviews to address any questions you may have.
                            </span>

                            <span className="card-medium-description">
                                2. <span className="important-text card-medium-description card-subtitle-alt-2">Maintain clear communication</span> <br/>
                                Before each service, make sure to share all relevant information with the caregiver about your children's specific needs, from allergies to daily routines.
                            </span>
            
                            <span className="card-medium-description">
                                3. <span className="important-text card-medium-description card-subtitle-alt-2">Mutual trust</span> <br/>
                                The foundation of a good experience is trust. Communicate any concerns you have with the caregiver or with our company so we can ensure everything is functioning perfectly.
                            </span>

                        </div>
                    </div>
                </div>

                <div className='medium-paragraph full-width huge-margin-top'>
                    At <span className="pink-text">Little Giggles Babysitting Company</span>
                    , we all work together to create a safe and trustworthy environment for your family. The safety of children is at the heart of everything we do.
                </div>

                <div className="section-separator section-separator-alternative full-width"></div>
                <div className="section-content self-left-align">
                    <h2 className='medium-font-size'>Get to know our standards</h2>
                </div>
                <div className="standard-card">
                    <div className="grid-2-columns standard-card-text no-gap">
                        <div className='list-element-alt full-width grid-two-columns-alt'>
                            <div className="small-ball"></div>
                            <span>Insured</span>
                        </div>
                        <div className='list-element-alt full-width grid-two-columns-alt'>
                            <div className="small-ball"></div>
                            <span>Trustworthy</span>
                        </div>
                        <div className='list-element-alt full-width grid-two-columns-alt'>
                            <div className="small-ball"></div>
                            <span>ResponsibleFully</span>
                        </div>
                        <div className='list-element-alt full-width grid-two-columns-alt'>
                            <div className="small-ball"></div>
                            <span>Screened</span>
                        </div>
                        <div className='list-element-alt full-width grid-two-columns-alt'>
                            <div className="small-ball"></div>
                            <span>C.P.R.-Certified</span>
                        </div>
                    </div>
                    <div className="flex-container standard-card-svg-section flex-column">
                        <SupremeStandardsSvg/>
                        SUPREME STANDARDS.
                    </div>
                </div>
            </div>
            
        </div>
    );
}