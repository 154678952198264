import { useState } from "react";
import { RequestBabySitterForm } from "./tabContent/RequestBabySitterForm";
import RequestBabySitterFormHotel from "./tabContent/RequestBabySitterFormHotel";
import "../../style-sheets/RequestBabySitterForm.css"; 
import { RequestBabySitterFormExistingClient } from "./tabContent/RequestBabySitterFormExistingClient";

const RequestMain = () => {
    const[activeElement,setActiveElement] = useState("item-1");

    const handleRenderClientOption = () => {
        switch(activeElement){
            case "item-1":
                return <RequestBabySitterForm/>

            case "item-2":
                return <RequestBabySitterFormExistingClient/>

            case "item-3":
                return <RequestBabySitterFormHotel/>
        }
    }
    return(
        <div className="section-container-alt babysitter-main-form">
            <form className="section-content-alt-2 flex-container margin-center flex-column">
                <div className="reservation-titles-container extra-gap full-width flex-container flex-column">

                    <div className="full-width">
                        <h2 className='medium-font-size reservation-titles'>Iam an</h2>
                    </div>
                    
                    <div className="self-left-align switch-container babysitter-form">
                        <div className={`item-switch head-switch ${activeElement == "item-1" ? "active-switch-item" : ""}`} onClick={() => setActiveElement("item-1")}>New Client</div>
                        <div className={`item-switch ${activeElement == "item-2" ? "active-switch-item" : ""}`} onClick={() => setActiveElement("item-2")}>Existing Client</div>
                        <div className={`item-switch tail-switch ${activeElement == "item-3" ? "active-switch-item" : ""}`} onClick={() => setActiveElement("item-3")}>Hotel / Concierge</div>
                    </div>

                    {handleRenderClientOption()}
                </div>

            </form>
        </div>
    )
}
export default RequestMain;