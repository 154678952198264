import '../style-sheets/Babysitter.css';

const Babysitter = () => {

    return(
        <div className="section-container babysitter-main-container flex-container flex-column">
            {/* <div className="about-header">
                <span className='section-img-title'>About us</span>
            </div> */}
            
            <div className='section-content-alt medium-paragraph full-width'>
                At <span className="pink-text">The Babysitting Company</span>, we stand out for the quality of our service and our babysitters. We guarantee the satisfaction
                of clients, children, and babysitters to ensure an experience that exceeds expectations. Our babysitters come from diverse
                professional backgrounds, including <span className="pink-text">medical assistants</span>, <span className="pink-text">psychologists</span> from other countries, <span className="pink-text">teachers</span>, <span className="pink-text">nurses</span>, <span className="pink-text">doctors</span> from
                their home countries, and more. Each is selected for their professionalism and commitment to providing the best care while
                maintaining confidentiality and discretion.
            </div>
                

            <div className="section-content-alt section-separator section-separator-alternative full-width"></div>

            <div className="section-content-alt grid-area-pre-requisites very-extra-gap top-alignment">
                
                
                <div className="flex-container flex-column list1">
                    <div className="list-title self-left-align">
                        BABYSITTERS SHOULD HAVE :
                    </div>
                    <div className="grid-2-columns extra-gap full-width list-categories">
                        <div className='list-element full-width grid-two-columns-alt left-alignment'>
                            <div className="small-ball"></div>
                            <span>Photo ID to show the client upon arrival </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>A fully charged phone to ensure they are reachable at all times </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Proper attire to wear during the service </span>
                        </div>
                    </div>
                </div>
                <div className="flex-container flex-column list2">
                    <div className="list-title self-left-align">
                        BABYSITTER QUALIFICATIONS:
                    </div>
                    <div className="grid-2-columns extra-gap full-width list-categories">
                        <div className='list-element full-width grid-two-columns-alt left-alignment'>
                            <div className="small-ball"></div>
                            <span>Successful completion of background check and drug screening </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>CPR certification </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Personally interviewed </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Thorough screening </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Verified references </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Non-smoker </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Over 18 years old </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>Fluent in English; Spanish if required </span>
                        </div>

                        <div className='list-element grid-two-columns-alt left-alignment full-width'>
                            <div className="small-ball"></div>
                            <span>For nursing services, verification of credentials and licensing</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Babysitter;