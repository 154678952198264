import { NavLink } from 'react-router-dom';
import '../style-sheets/Process.css';

const Process = () => {

    return(
        <div className="section-container pre-reservation-main-container flex-container flex-column">
            {/* <div className="about-header">
                <span className='section-img-title'>About us</span>
            </div> */}
                <div className="flex-container flex-column section-content-alt list-elements-container-alt-2 full-width">
                    <div className="list-title self-left-align">
                        Our Service Request Process
                    </div>
                    <div className='list-element-alt full-width grid-two-columns-alt left-alignment'>
                        <div className="medium-ball">1</div>
                        <span>To request a reservation, fill out the <span className="pink-text"><NavLink to="/request-babysitter">“Request a Reservation”</NavLink></span> form with as many details as possible or <span className="pink-text"><a href='tel:+13055270300'>call us directly</a></span>.</span>
                    </div>
                    <div className='list-element-alt full-width grid-two-columns-alt left-alignment'>
                        <div className="medium-ball">2</div>
                        <span>First, the company will contact you to learn more about your preferences.</span>
                    </div>
                    <div className='list-element-alt full-width grid-two-columns-alt left-alignment'>
                        <div className="medium-ball">3</div>
                        <span>Then, we will send you an email with a summary of the available candidates. </span>
                    </div>
                    <div className='list-element-alt full-width grid-two-columns-alt left-alignment'>
                        <div className="medium-ball">4</div>
                        <span>If desired, you can conduct a Zoom or in-person interview with the babysitter before they start working with your child. </span>
                    </div>
            </div>
                

            <div className="section-content-alt section-separator section-separator-alternative full-width"></div>

            <div className="flex-container flex-column very-extra-gap section-content-alt">
                <div className="flex-container flex-column card-alt-3 card left-alignment">
                    <span className="card-main-title full-width pink-underline">YOUR SITTER</span>
                    <span className="card-subtitle-alt full-width">Once the sitter is chosen, you will receive their full profile, including their name, contact information, biography, and photo. We work carefully with clients and sitters to ensure that everyone is happy with the match.</span>
                    <span className="card-subtitle-alt full-width important-text">Please note that for last-minute services, the company requires a minimum of 6 hours' notice. For regular bookings, we have up to 5 days to find a sitter who meets your expectations.</span>
                </div>

                <div className="flex-container flex-column card-alt-3 card left-alignment">
                    <span className="card-main-title full-width pink-underline">SATISFACTION COMMITMENT</span>
                    <span className="card-subtitle-alt full-width">At The Babysitting Company, we strive to provide you with an exceptional childcare experience. If at any time during the service you are not completely satisfied with your babysitter, we encourage you to contact us immediately. We are committed to providing an alternative solution that meets your expectations and needs..</span>
                    {/* <span className="card-subtitle-alt full-width important-text">Please note that for last-minute services, the company requires a minimum of 6 hours' notice. For regular bookings, we have up to 5 days to find a sitter who meets your expectations.</span> */}
                </div>
            </div>


        </div>
    );
}
export default Process;