import { useContext, useState } from "react";
import { CitySvg } from "../../svg_components/CitySvg";
import { EmailSvg } from "../../svg_components/EmailSvg";
import { HomeAddressSvg } from "../../svg_components/HomeAddressSvg";
import { LocationStateSvg } from "../../svg_components/LocationSateSvg";
import { PhoneSvg } from "../../svg_components/PhoneSvg";
import { UserSvg } from "../../svg_components/UserSvg";
import { ZipCodeSvg } from "../../svg_components/ZipCodeSvg";
import { CustomSelect } from "../../custom_components/custom_select_component/CustomSelect";
import { PetSvg } from "../../svg_components/PetSvg";
import { CalendarSvg } from "../../svg_components/CalendarSf";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LanguageSvg } from "../../svg_components/LanguageSvg";
import { GenderSvg } from "../../svg_components/GenderSvg";
import { SwimSvg } from "../../svg_components/SwimSvg";
import { DriveSvg } from "../../svg_components/DriveSvg";
import { ServicesSvg } from "../../svg_components/ServicesSvg";
import { CarriageSvg } from "../../svg_components/CarriageSvg";
import ReactQuillWrapper from "../../ReactQuillWrapper";
import ReactFlatpickrWrapper from "../../ReactFlatpickrWrapper";
import { Alert } from "../../alert_components/Alert/Alert";
import { ApiContext } from "../../ApiContext";
import { handleFormChange, mapDataAndCheckValid } from "../../../utils/utils";

export const RequestBabySitterFormExistingClient = () => {
    const { BASE_URL,PORT } = useContext(ApiContext);

    const [formCheckboxes,setFormCheckboxes] = useState({
        "different-name-check":false,
        "different-location-check":false,
        "address-need-changes":false,
        "pet-info-need-changes":false,
        "allergies-need-changes-1":false,
        "special-needs-need-changes-1":false
    })
    const [formDataAddress,setFormDataAddress] = useState({
        "home-address": {"value" : "","error" : false,"type" : "character","required" : true},
        "city": {"value" : "","error" : false,"type" : "character","required" : true},
        "state": {"value" : "","error" : false,"type" : "character","required" : true},
        "zip-code": {"value" : "","error" : false,"type" : "character","required" : true},
    });
    
    const [formDataChildren,setFormDataChildren] = useState({
        "child_name_1":{"value" : "","error" : false,"type" : "character","required" : true},
        "child_age_1":{"value" : "","error" : false,"type" : "character","required" : true},
        "child_birth_1":{"value" : "","error" : false,"type" : "date","required" : true},
        "child_gender_1":{"value" : "","error" : false,"type" : "character","required" : true},
    });

    const [formDataPet,setFormDataPet] = useState({        
        "pet-information" :{"value" : "","error" : false,"type" : "character","required" : true},
    });

    const [formData,setFormData] = useState({
        "first-name": {"value" : "","error" : false,"type" : "character","required" : true},
        "last-name": {"value" : "","error" : false,"type" : "character","required" : true},
        "phone-number": {"value" : "","error" : false,"type" : "number","required" : true},
        "email-address": {"value" : "","error" : false,"type" : "email","required" : true},
        "reservation-type":{"value" : "","error" : false,"type" : "character","required" : true},
        "language":{"value" : "","error" : false,"type" : "character","required" : true},
        "swim":{"value" : "","error" : false,"type" : "character","required" : true},
        "driver-license":{"value" : "","error" : false,"type" : "character","required" : true},
    });

    const [formDataReservationNameDifferent,setFormDataReservationNameDifferent] = useState({
        "full-name-reservation": {"value" : "","error" : false,"type" : "character","required" : true},
        "phone-number-reservation": {"value" : "","error" : false,"type" : "phone","required" : true},
        "email-reservation": {"value" : "","error" : false,"type" : "email","required" : true},
    });

    const [formDataReservationLocationDifferent,setFormDataReservationLocationDifferent] = useState({
        "location": {"value" : "","error" : false,"type" : "character","required" : true},
        "full-address": {"value" : "","error" : false,"type" : "character","required" : true},
    });

    const [numOfChildren, setNumOfChildren] = useState(1);

     const [objectChildrenCheck, setObjectChildrenCheck] = useState({
        1 : false,
        2 : false,
        3 : false,
        4 : false,
    });
    const [petInfoChanges,setPetInfoChanges] = useState(false);
    const [numChildrenChanges,setNumChildrenChanges] = useState(false);
    

    const LIMIT_CHILDREN = 5;

    const translateDict = {
        1 : "SECOND",
        2 : "THIRD",
        3 : "FOURTH",
        4 : "FIFTH",
    }

    const genderData = [
        {
        "text" : "Male",
        "value" : "male",
        },
        {
        "text" : "Female",
        "value" : "female",
        },
    ];  

    const swimData = [
        {
        "text" : "Yes",
        "value" : "yes",
        },
        {
        "text" : "No",
        "value" : "no",
        },
    ];   

    const typeOfReservation = [
        {
        "text" : "part time",
        "value" : "part_time",
        },
        {
        "text" : "temporal",
        "value" : "temporal",
        },
        {
        "text" : "hourly",
        "value" : "hourly",
        },
        {
        "text" : "full time",
        "value" : "full_time",
        },
        {
        "text" : "at night",
        "value" : "at_night",
        },
    ];
     
 

    const petsData = [
        {
        "text" : "No pets",
        "value" : "no_pets",
        },
        {
        "text" : "Cat",
        "value" : "cat",
        },
        {
        "text" : "Small Dog",
        "value" : "small_dog",
        },
        {
        "text" : "Large Dog",
        "value" : "large_dog",
        },
        {
        "text" : "other",
        "value" : "other",
        },
    ];
    
    const handleFormFieldChange = (e) => {
        handleFormChange(e.target,formData,setFormData);
    }

    const handleFormChildrenChange = (e) => {
        handleFormChange(e.target,formDataChildren,setFormDataChildren);
    }
    
    const handleFormPetChange = (e) => {
        handleFormChange(e.target,formDataPet,setFormDataPet);
    }
    

    const handleFormFieldChangeNameDifferent = (e) => {
        handleFormChange(e.target,formDataReservationNameDifferent,setFormDataReservationNameDifferent);
    }

    const handleFormFieldAddressChanges = (e) => {
        handleFormChange(e.target,formDataAddress,setFormDataAddress);
    }
    
    const handleFormFieldChangeLocationDifferent = (e) => {
        handleFormChange(e.target,formDataReservationLocationDifferent,setFormDataReservationLocationDifferent);
    }

    const handleFormSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let customData = {}

        let canSend = true;
        let errorFields = [];
        let firstErrorElementName = "";
        let tempResult = undefined;

        //NORMAL FORM DATA
        tempResult = mapDataAndCheckValid(formData,setFormData,numOfChildren);
        Object.assign(customData,tempResult['data']);

        if(firstErrorElementName == "")
            firstErrorElementName = tempResult['firstErrorElementName'];
        
        errorFields = [...errorFields,...tempResult['errorFields']];
        
        //RESERVATION ADDITIONAL DATA IF NAME IS DIFFERENT
        if(formCheckboxes['different-name-check']) {
            tempResult = mapDataAndCheckValid(formDataReservationNameDifferent,setFormDataReservationNameDifferent,numOfChildren);
            Object.assign(customData,tempResult['data']);

            if(firstErrorElementName == "")
                firstErrorElementName = tempResult['firstErrorElementName'];

            errorFields = [...errorFields,...tempResult['errorFields']];
            canSend = canSend && (errorFields.length == 0);
        }

        //RESERVATION ADDITIONAL DATA IF LOCATION IS DIFFERENT
        if(formCheckboxes['different-location-check']) {
            tempResult = mapDataAndCheckValid(formDataReservationLocationDifferent,setFormDataReservationLocationDifferent,numOfChildren);
            Object.assign(customData,tempResult['data']);

            if(firstErrorElementName == "")
                firstErrorElementName = tempResult['firstErrorElementName'];
            
            errorFields = [...errorFields,...tempResult['errorFields']];
            canSend = canSend && (errorFields.length == 0)
        }

        //RESERVATION ADDITIONAL DATA IF ADDRESS NEED CHANGES
        if(formCheckboxes['address-need-changes']) {
            tempResult = mapDataAndCheckValid(formDataAddress,setFormDataAddress,numOfChildren);
            Object.assign(customData,tempResult['data']);

            if(firstErrorElementName == "")
                firstErrorElementName = tempResult['firstErrorElementName'];
            
            errorFields = [...errorFields,...tempResult['errorFields']];
            canSend = canSend && (errorFields.length == 0)
        }
        
        //RESERVATION ADDITIONAL DATA IF ADDRESS NEED CHANGES
        if(numChildrenChanges) {
            tempResult = mapDataAndCheckValid(formDataChildren,setFormDataChildren,numOfChildren);
            Object.assign(customData,tempResult['data']);

            if(firstErrorElementName == "")
                firstErrorElementName = tempResult['firstErrorElementName'];
            
            errorFields = [...errorFields,...tempResult['errorFields']];
            canSend = canSend && (errorFields.length == 0)
        }
        if(formCheckboxes['pet-info-need-changes']) {
            tempResult = mapDataAndCheckValid(formDataPet,setFormDataPet,numOfChildren);
            Object.assign(customData,tempResult['data']);

            if(firstErrorElementName == "")
                firstErrorElementName = tempResult['firstErrorElementName'];
            
            errorFields = [...errorFields,...tempResult['errorFields']];
            canSend = canSend && (errorFields.length == 0)
        }
        
        
        canSend = canSend && (errorFields.length == 0);

        customData['client_type'] = "existing client";
        customData['reservation_type'] = "babysitter";

        
        Object.assign(customData,formCheckboxes);

        if(canSend){

            let result = await fetch(`${BASE_URL}${PORT}/reservation`,{
                method:"POST",
                headers:{ "Content-Type": "application/json"},
                body:JSON.stringify(customData)
            });

            console.log(formDataChildren,customData)
            
            Alert.fire({
                "text":`Request successfully sended`,
                "type":"success"
            })
        }else{
            let fieldsNames = "";
            errorFields.map((d,index) => {
                let label = document.querySelector(`label[for="${d}"]`);
                let spanCustomSelect = document.querySelector(`span[name='${d}']`);

                if(label){
                    fieldsNames += label.innerText + (index == d.length ? '' : ',');
                }else{
                    console.log("spanCustomSelect",d,spanCustomSelect)
                    fieldsNames += spanCustomSelect.innerText + (index == d.length ? '' : ',');
                }
            })
            // Alert.fire({
            //     "text":`Check the next fields <br> ${fieldsNames}`,
            //     "type":"error"
            // })
            Alert.fire({
                "text":`Check the fields`,
                "type":"error"
            })
            document.getElementsByName(firstErrorElementName)[0].scrollIntoView();
        }
    }

    const handleChildrenClick = (e) => {
        let index = e.target.classList[1].split("_")[2];
        let temp = objectChildrenCheck;
        let temp_2 = formDataChildren;
        let temp_3 = formCheckboxes;

        if(e.target.checked){
            temp[index] = true;
            setObjectChildrenCheck(temp)
            

            if(!temp_2[`child_name_${parseInt(index) + 1}`]) {
                temp_2[`child_name_${parseInt(index) + 1}`] = {"value":"","error":false,"type" : "character","required" : true};
                temp_2[`child_age_${parseInt(index) + 1}`] = {"value":"","error":false,"type" : "character","required" : true};
                temp_2[`child_birth_${parseInt(index) + 1}`] = {"value":"","error":false,"type" : "date","required" : true};
                temp_2[`child_gender_${parseInt(index) + 1}`] = {"value":"","error":false,"type" : "character","required" : true};
                
            }

            if(!temp_3[`special-needs-need-changes-${parseInt(index) + 1}`]) {
                temp_3[`special-needs-need-changes-${parseInt(index) + 1}`] = false;
                temp_3[`allergies-need-changes-${parseInt(index) + 1}`] = false;
            }
            console.log(temp_3);
            setFormCheckboxes(temp_3);
        }else{
            for(let i = index;i < LIMIT_CHILDREN;i++){
                temp[i] = false;

                delete temp_2[`child_name_${parseInt(index) + 1}`];
                delete temp_2[`child_age_${parseInt(index) + 1}`];
                delete temp_2[`child_birth_${parseInt(index) + 1}`];
                delete temp_2[`child_gender_${parseInt(index) + 1}`];
            }
            setObjectChildrenCheck(temp);
        }
        let filteredArray = Object.keys(objectChildrenCheck).filter((d) => {
            return objectChildrenCheck[d]
        })


        setFormDataChildren(temp_2);
        setNumOfChildren(filteredArray.length + 1);
    }
    

    const handleChangeFormCheckboxes = (e) => {
        setFormCheckboxes((prevData) => ({...prevData,[e.target.name] : e.target.checked}));
        let temp = {};
        
        switch(e.target.name){
            case "address-need-changes":
                temp = {
                    "home-address": {"value" : "","error" : false,"type" : "character","required" : true},
                    "city": {"value" : "","error" : false,"type" : "character","required" : true},
                    "state": {"value" : "","error" : false,"type" : "character","required" : true},
                    "zip-code": {"value" : "","error" : false,"type" : "character","required" : true},
                }
                setFormDataAddress(temp);
                break;
            case "different-name-check":
                temp = {
                    "full-name-reservation": {"value" : "","error" : false,"type" : "character","required" : true},
                    "phone-number-reservation": {"value" : "","error" : false,"type" : "number","required" : true},
                    "email-reservation": {"value" : "","error" : false,"type" : "email","required" : true},
                }
                setFormDataReservationNameDifferent(temp);
                break;
            case "different-location-check":
                temp = {
                    "location": {"value" : "","error" : false,"type" : "character","required" : true},
                    "full-address": {"value" : "","error" : false,"type" : "character","required" : true},
                }
                setFormDataReservationLocationDifferent(temp);
                break;
            case "pet-info-need-changes":
                temp = {
                    "pet-information": {"value" : "","error" : false,"type" : "character","required" : true},
                }
                setFormDataPet(temp);
        }
    }

    return (
           <>
                {/* FIRST BLOCK */}
                <div className="full-width">
                    <h2 className='medium-font-size reservation-titles'>Client Information</h2>
                </div>

                <div className="grid-4-columns small-margin-top full-width">
                    <div className={`ContInpPlaceholder babysitter-form ${formData['first-name']['error'] ? 'error-alt' : ''}`}>
                        <UserSvg/>
                        <input onChange={handleFormFieldChange} id="first-name" className="greater-width blue-border small-height left-alignment form-input" value={formData['first-name']['value']} name="first-name" type="text" required/>
                        <label className="placeholder form-label" htmlFor="first-name">First Name <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formData['last-name']['error'] ? 'error-alt' : ''}`}>
                        <UserSvg/>
                        <input onChange={handleFormFieldChange} id="last-name" className="greater-width blue-border small-height left-alignment form-input" value={formData['last-name']['value']} name="last-name" type="text" required/>
                        <label className="placeholder form-label" htmlFor="last-name">Last Name <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formData['phone-number']['error'] ? 'error-alt' : ''}`}>
                        <PhoneSvg/>
                        <input onChange={handleFormFieldChange} id="phone-number" className="greater-width blue-border small-height left-alignment form-input" value={formData['phone-number']['value']} name="phone-number" type="text" required/>
                        <label className="placeholder form-label" htmlFor="phone-number">Phone Number <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formData['email-address']['error'] ? 'error-alt' : ''}`}>
                        <EmailSvg/>
                        <input onChange={handleFormFieldChange} id="email-address" className="greater-width blue-border small-height left-alignment form-input" value={formData['email-address']['value']} name="email-address" type="text" required/>
                        <label className="placeholder form-label" htmlFor="email-address">Email Address <span className='pink-text'>*</span></label>
                    </div>
                </div>

                <span className="important-text little-font-size">Please check yes or no on the following items to let us know if your reservation information has changed since your last reservation. If the following items have not changed you don't have to do nothing. If any of your information has changed since your last reservation, please check UPDATES NEEDED and fill out the required information.</span>   
                
                <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                    <label className="switch">
                        <input type="checkbox" name={'address-need-changes'} checked={formCheckboxes['address-need-changes']} onChange={handleChangeFormCheckboxes}/>
                        <span className="slider round"></span>
                    </label>
                    <p>Address need updates? </p>
                </div>

                <div className={`grid-4-columns small-margin-top full-width ${formCheckboxes['address-need-changes'] ? '' : 'hidden'}`}>
                    <div className={`ContInpPlaceholder babysitter-form ${formDataAddress['home-address']['error'] ? 'error-alt' : ''}`}>
                        <HomeAddressSvg/>
                        <input onChange={handleFormFieldAddressChanges} id="home-address" className="greater-width blue-border small-height left-alignment form-input" value={formDataAddress['home-address']['value']} name="home-address" type="text" required/>
                        <label className="placeholder form-label" htmlFor="home-address">Home address <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formDataAddress['city']['error'] ? 'error-alt' : ''}`}>
                        <CitySvg/>
                        <input onChange={handleFormFieldAddressChanges} id="city" className="greater-width blue-border small-height left-alignment form-input" value={formDataAddress['city']['value']} name="city" type="text" required/>
                        <label className="placeholder form-label" htmlFor="city">City <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formDataAddress['state']['error'] ? 'error-alt' : ''}`}>
                        <LocationStateSvg/>
                        <input onChange={handleFormFieldAddressChanges} id="state" className="greater-width blue-border small-height left-alignment form-input" value={formDataAddress['state']['value']} name="state" type="text" required/>
                        <label className="placeholder form-label" htmlFor="state">State <span className='pink-text'>*</span></label>
                    </div>
                    <div className={`ContInpPlaceholder babysitter-form ${formDataAddress['zip-code']['error'] ? 'error-alt' : ''}`}>
                        <ZipCodeSvg/>
                        <input onChange={handleFormFieldAddressChanges} id="zip-code" className="greater-width blue-border small-height left-alignment form-input" value={formDataAddress['zip-code']['value']} name="zip-code" type="text" required/>
                        <label className="placeholder form-label" htmlFor="zip-code">Zip Code/ Postal Code <span className='pink-text'>*</span></label>
                    </div>
                </div>
                
                <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                    <label className="switch">
                        <input type="checkbox" name="different-name-check" checked={formCheckboxes['different-name-check']} onChange={handleChangeFormCheckboxes}/>
                        <span className="slider round"></span>
                    </label>
                    <p>If reservation name is different than client name</p>
                </div>


                <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                    <label className="switch">
                        <input type="checkbox" name="different-location-check" checked={formCheckboxes['different-location-check']} onChange={handleChangeFormCheckboxes}/>
                        <span className="slider round"></span>
                    </label>
                    <p>If reservation is at a different location </p>
                </div>

                <div className="hidden-container">
                    <div className={`grid-4-columns small-margin-top full-width ${formCheckboxes['different-name-check'] ? '' : 'hidden'}`}>
                        <div className={`ContInpPlaceholder babysitter-form ${formDataReservationNameDifferent['full-name-reservation']['error'] ? 'error-alt' : ''}`}>
                            <UserSvg/>
                            <input onChange={handleFormFieldChangeNameDifferent} id="full-name-reservation" className="greater-width blue-border small-height left-alignment form-input" value={formDataReservationNameDifferent['full-name-reservation']['value']} name="full-name-reservation" type="text" required/>
                            <label className="placeholder form-label" htmlFor="full-name-reservation">Full Name For Reservation <span className='pink-text'>*</span></label>
                        </div>
                        <div className={`ContInpPlaceholder babysitter-form ${formDataReservationNameDifferent['phone-number-reservation']['error'] ? 'error-alt' : ''}`}>
                            <PhoneSvg/>
                            <input onChange={handleFormFieldChangeNameDifferent} id="phone-number-reservation" className="greater-width blue-border small-height left-alignment form-input" value={formDataReservationNameDifferent['phone-number-reservation']['value']} name="phone-number-reservation" type="text" required/>
                            <label className="placeholder form-label" htmlFor="phone-number-reservation">Phone Number <span className='pink-text'>*</span></label>
                        </div>
                        <div className={`ContInpPlaceholder babysitter-form ${formDataReservationNameDifferent['email-reservation']['error'] ? 'error-alt' : ''}`}>
                            <EmailSvg/>
                            <input onChange={handleFormFieldChangeNameDifferent} id="email-reservation" className="greater-width blue-border small-height left-alignment form-input" value={formDataReservationNameDifferent['email-reservation']['value']} name="email-reservation" type="text" required/>
                            <label className="placeholder form-label" htmlFor="email-reservation">Email Address <span className='pink-text'>*</span></label>
                        </div>
                    </div>

                    <div className={`grid-4-columns full-width ${formCheckboxes['different-location-check'] ? '' : 'hidden'}`}>
                        <div className={`ContInpPlaceholder babysitter-form ${formDataReservationLocationDifferent['location']['error'] ? 'error-alt' : ''}`}>
                            <LocationStateSvg/>
                            <input onChange={handleFormFieldChangeLocationDifferent} id="location" className="greater-width blue-border small-height left-alignment form-input" value={formDataReservationLocationDifferent['location']['value']} name="location" type="text" required/>
                            <label className="placeholder form-label" htmlFor="location">Location Name (if Applicable) <span className='pink-text'>*</span></label>
                        </div>
                        <div className={`ContInpPlaceholder babysitter-form ${formDataReservationLocationDifferent['full-address']['error'] ? 'error-alt' : ''}`}>
                            <HomeAddressSvg/>
                            <input onChange={handleFormFieldChangeLocationDifferent} id="full-address" className="greater-width blue-border small-height left-alignment form-input" value={formDataReservationLocationDifferent['full-address']['value']} name="full-address" type="text" required/>
                            <label className="placeholder form-label" htmlFor="full-address">Full address  (Where Reservation is Requested) <span className='pink-text'>*</span></label>
                        </div>
                    </div>
                </div>

                <div className="section-separator section-separator-alternative full-width"></div>

                {/* SECOND BLOCK */}

                <div className="full-width">
                    <h2 className='medium-font-size reservation-titles'>Additional Reservation Information</h2>
                </div>

                <div className='grid-4-columns full-width'>
                
                    <div className='grid-4-columns small-margin-top full-width'>
                        <div className={`ContInpPlaceholder babysitter-form ${formData['reservation-type']['error'] ? 'error-alt' : ''}`}>
                            <ServicesSvg/>
                            <div className="custom-select-wrapper">
                                <CustomSelect customClassName={"capitalize-text"} onChange={handleFormFieldChange} name="reservation-type" placeholder={"Type of service <span class='pink-text'>*</span>"}  noResults={"No options"} noOPtions={"No options"} data={typeOfReservation} placeholderSearchBar={"Search.."}/>
                            </div>
                        </div>
                    </div>
                </div>

                

                <div className="no-padding  flex-container flex-column full-width">
                    <div className="flex-container self-left-align small-height">
                        <h2 className="placeholder form-label" htmlFor="reservation-date">Reservation Information</h2>
                    </div>
                    <ReactQuillWrapper name={"reservation_information"} onChange={handleFormFieldChange}/>
                </div>


                <div className="full-width flex-container flex-column no-padding">
                    <div className="full-width reservation-titles-container">
                        <h2 className='medium-font-size reservation-titles'>Children's Details</h2>
                    </div>


                    <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                        <label className="switch">
                            <input type="checkbox" onClick={(e) => {
                                if(e.target.checked) 
                                    setNumChildrenChanges(true) 
                                else{
                                    setNumChildrenChanges(false);
                                    handleChildrenClick({target : {classList : ["check_child","check_child_1"],checked: false}});
                                    }
                                }
                            }/>
                            <span className="slider round"></span>
                        </label>
                        <p>Children NEED UPDATES </p>
                    </div>

                    {Array.from({ length: numOfChildren }).map((_, index) => {
                        if(index == LIMIT_CHILDREN) return;

                        return(

                            <div key={index} className={`no-padding small-margin-top full-width flex-container flex-column ${numChildrenChanges ? '' : 'hidden'}`}>
                                <div className='grid-4-columns small-margin-top full-width'>
                                    <div className={`ContInpPlaceholder babysitter-form ${formDataChildren[`child_name_${index + 1}`]['error'] ? 'error-alt' : ''}`}>
                                        <UserSvg/>
                                        <input onChange={handleFormChildrenChange} id={`child_name_${index + 1}`} className="greater-width blue-border small-height left-alignment form-input" value={formDataChildren[`child_name_${index + 1}`]['value']} name={`child_name_${index + 1}`} type="text" required/>
                                        <label className="placeholder form-label" htmlFor={`child_name_${index + 1}`}>Child's Name <span className='pink-text'>*</span></label>
                                    </div>

                                    <div className={`ContInpPlaceholder babysitter-form ${formDataChildren[`child_age_${index + 1}`]['error'] ? 'error-alt' : ''}`}>
                                        <CarriageSvg/>
                                        <input onChange={handleFormChildrenChange} id={`child_age_${index + 1}`} className="greater-width blue-border small-height left-alignment form-input" value={formDataChildren[`child_age_${index + 1}`]['value']} name={`child_age_${index + 1}`} type="text" required/>
                                        <label className="placeholder form-label" htmlFor={`child_age_${index + 1}`}>Age <span className='pink-text'>*</span></label>
                                    </div>
                                    
                                    <div className={`ContInpPlaceholder babysitter-form ${formDataChildren[`child_birth_${index + 1}`]['error'] ? 'error-alt' : ''}`}>
                                        <CalendarSvg/>
                                        <div className="flatpickr-container">
                                            <ReactFlatpickrWrapper onClose={() => (formDataChildren[`child_birth_${index + 1}`]['value'] != undefined && formDataChildren[`child_birth_${index + 1}`]['value'] != "") ? '' : document.querySelector(`.date-birth-label-${index + 1}`).classList.remove("flatpickr-active")} onOpen={() => document.querySelector(`.date-birth-label-${index + 1}`).classList.add("flatpickr-active")} name={`child_birth_${index + 1}`} onChange={handleFormChildrenChange}/>
                                            <label htmlFor={`child_birth_${index + 1}`} className={`placeholder birth-label form-label date-birth-label-${index + 1}`}>Date of Birth <span className='pink-text'>*</span></label>
                                        </div>
                                    </div>
                                    

                                    <div className={`ContInpPlaceholder babysitter-form ${formDataChildren[`child_gender_${index + 1}`]['error'] ? 'error-alt' : ''}`}>
                                        <GenderSvg/>
                                        <div className="custom-select-wrapper">
                                            <CustomSelect customClassName={"capitalize-text"} onChange={handleFormChildrenChange} name={`child_gender_${index + 1}`} placeholder={"Please select gender <span class='pink-text'>*</span>"}  noResults={"No options"} noOPtions={"No options"} data={genderData} placeholderSearchBar={"Search.."}/>
                                        </div>
                                    </div>

                                </div>

                                <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                                    <label className="switch">
                                        <input type="checkbox" name={`special-needs-need-changes-${index + 1}`} checked={formCheckboxes[`special-needs-need-changes-${index + 1}`]} onChange={handleChangeFormCheckboxes}/>
                                        <span className="slider round"></span>
                                    </label>
                                    <p>Special needs need updates? </p>
                                </div>

                                <div className={`no-padding  flex-container flex-column full-width ${formCheckboxes[`special-needs-need-changes-${index + 1}`] ? '' : 'hidden'}`}>
                                    <div className="flex-container self-left-align small-height">
                                        <h2 className="placeholder form-label">Special needs</h2>
                                    </div>
                                    <ReactQuillWrapper name={`child_special_needs_${index + 1}`} onChange={handleFormChildrenChange}/>
                                </div>                        
                                
                                <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                                    <label className="switch">
                                        <input type="checkbox" name={`allergies-need-changes-${index + 1}`} checked={formCheckboxes[`allergies-need-changes-${index + 1}`]} onChange={handleChangeFormCheckboxes}/>
                                        <span className="slider round"></span>
                                    </label>
                                    <p>Allergies need updates? </p>
                                </div>
                                
                                <div className={`no-padding  flex-container flex-column full-width ${formCheckboxes[`allergies-need-changes-${index + 1}`] ? '' : 'hidden'}`}>
                                    <div className="flex-container self-left-align small-height">
                                        <h2 className="placeholder form-label">Allergies</h2>
                                    </div>
                                    <ReactQuillWrapper name={`child_allergies_${index + 1}`} onChange={handleFormChildrenChange}/>
                                </div>
                                {index + 1 == LIMIT_CHILDREN ? '' : 
                                    <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                                        <label className="switch">
                                            <input type="checkbox" checked={objectChildrenCheck[index + 1]} className={`check_child check_child_${index + 1}`} onChange={handleChildrenClick}/>
                                            <span className="slider round"></span>
                                        </label>
                                        <p>Add a {translateDict[index + 1]} Child </p>
                                    </div>
                                }
                            </div>
                        );
                        
                    })}


                    <div className="no-padding toggle-switch-container toggle-switch-container-alt self-left-align">
                        <label className="switch">
                            <input type="checkbox" name={'pet-info-need-changes'} checked={formCheckboxes['pet-info-need-changes']} onChange={handleChangeFormCheckboxes}/>
                            <span className="slider round"></span>
                        </label>
                        <p>Pet Information NEED UPDATES </p>
                    </div>

                    <div className={`grid-4-columns hidden-container full-width ${formCheckboxes['pet-info-need-changes'] ? '' : 'hidden'}`}>

                        <div className={`ContInpPlaceholder babysitter-form ${formDataPet['pet-information']['error'] ? 'error-alt' : ''}`}>
                            <PetSvg/>
                            <div className="custom-select-wrapper">
                                <CustomSelect customClassName={"capitalize-text"} onChange={handleFormPetChange} name="pet-information" placeholder={"Pet information <span class='pink-text'>*</span>"}  noResults={"No options"} noOPtions={"No options"} data={petsData} placeholderSearchBar={"Search.."}/>
                            </div>
                        </div>
                    </div>
                </div>
            

                <div className="section-separator section-separator-alternative full-width"></div>

                {/* FOURTH BLOCK */}
                <div className="reservation-titles-container full-width flex-container flex-column">
                    <div className="full-width">
                        <h2 className='medium-font-size reservation-titles'>Sitter Preferences</h2>
                    </div>

                    <div className="grid-4-columns full-width">
                        <div className={`ContInpPlaceholder babysitter-form ${formData['language']['error'] ? 'error-alt' : ''}`}>
                            <LanguageSvg/>
                            <input onChange={handleFormFieldChange} id="language" className="greater-width blue-border small-height left-alignment form-input" value={formData['language']['value']} name="language" type="text" required/>
                            <label className="placeholder form-label" htmlFor="language">Language preference <span className='pink-text'>*</span></label>
                        </div>
                        <div className={`ContInpPlaceholder babysitter-form ${formData['swim']['error'] ? 'error-alt' : ''}`}>
                            <SwimSvg/>
                            <div className="custom-select-wrapper">
                                <CustomSelect customClassName={"capitalize-text"} onChange={handleFormFieldChange} name="swim" value={formData['swim']['value']} placeholder={"Do you need a sitter who swims? <span class='pink-text'>*</span>"}  noResults={"No options"} noOPtions={"No options"} data={swimData} placeholderSearchBar={"Search.."}/>
                            </div>
                        </div>
                        <div className={`ContInpPlaceholder babysitter-form ${formData['driver-license']['error'] ? 'error-alt' : ''}`}>
                            <DriveSvg/>
                            <div className="custom-select-wrapper">
                                <CustomSelect customClassName={"capitalize-text"} onChange={handleFormFieldChange} name="driver-license" value={formData['driver-license']['value']} placeholder={"Do you need a sitter who drives? <span class='pink-text'>*</span>"}  noResults={"No options"} noOPtions={"No options"} data={swimData} placeholderSearchBar={"Search.."}/>
                            </div>
                        </div>
                    </div>

                    <div className="no-padding  flex-container flex-column full-width">
                        <div className="flex-container self-left-align small-height">
                            <h2 className="placeholder form-label" htmlFor="reservation-date">Please tell us about your ideal sitter</h2>
                        </div>
                        <ReactQuillWrapper name={`ideal_sitter`} onChange={handleFormFieldChange}/>
                    </div>

                    <div className="no-padding  flex-container flex-column full-width">
                        <div className="flex-container self-left-align small-height">
                            <h2 className="placeholder form-label" htmlFor="reservation-date">Additional comments</h2>
                        </div>
                        <ReactQuillWrapper name={`additional_comments`} onChange={handleFormFieldChange}/>
                    </div>
                    <input type="submit" onClick={handleFormSubmit} className="standard-pink-button submit-button self-left-align" value="Submit"/>
                </div>
            </>
        );
}